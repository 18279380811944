<template>
  <div>
    <div class="col-sm d-flex align-left">
        <button type="button" class="btn btn-info" @click="showOptionsModal = true">
        <i class="fas fa-camera fa-2x"></i>
        </button>
    </div>
    <modal ref="modal" :show="showModal" @update:show="showModal = $event" @close="closeModal">
      <qrcode-stream
        v-if="scannerActive && !isIphone"
        ref="qrcodeStream"
        @decode="onDecode"
        :constraints="{ video: {
          facingMode: 'environment',
          zoom: 1,
          focusMode: ['continuous'],
          width: { ideal: 1280 },
          height: { ideal: 720 },
          aspectRatio: { ideal: 1.7777777778 }
          } }"
      ></qrcode-stream>
      <StreamBarcodeReader
        v-if="scannerActive && isIphone"
        ref="barcodeReader"
        @decode="onDecode"
      ></StreamBarcodeReader>
    </modal>

    <modal :show.sync="showOptionsModal" class="white-content">
      <h3 class="card-title">{{$t('tools.ReserveOrUnreserve')}}</h3>
      <div class="row">
        <div class="col-sm">
          <base-button block class="reserve-button" @click="activateScannerReserve">{{$t('tools.reserve')}}</base-button>
        </div>
        <div class="col-sm">
          <base-button block type="danger" @click="activateScannerReturn">{{$t('tools.unreserve')}}</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { Modal } from '@/components';
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  components: {
    StreamBarcodeReader,
    QrcodeStream,
    Modal,
  },
  data() {
    return {
      isIphone: false,
      scannerActive: false,
      showModal: false,
      showOptionsModal: false,
      decodedContent: '',
      reserve: false,
      return: false,
      constraints: {
        video: {
          facingMode: 'environment',
          zoom: 1,
          focusMode: ['continuous'],
          width: { ideal: 1280 },
          height: { ideal: 720 },
          aspectRatio: { ideal: 1.7777777778 }
        }
      },
    };
  },
  created() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.checkIfIphone();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopAllStreams();
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        this.stopAllStreams();
      } else if (this.scannerVisible) {
        this.initializeCamera();
      }
    },
    stopAllStreams() {
      if (this.isIphone) {
        this.stopStream(this.$refs.barcodeReader);
      }
      else {
        this.stopStream(this.$refs.qrcodeStream);
      }
      this.scannerActive = false;
    },
    stopStream(ref) {
      if (ref && ref.$el) {
        const videoElement = ref.$el.querySelector('video') || ref.$el;
        if (videoElement && videoElement.srcObject) {
          const stream = videoElement.srcObject;
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
            videoElement.srcObject = null;
          }
        }
      }
    },
    async initializeCamera() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      const preferredCamera = videoDevices.find(device =>
        !['Ultra Wide', 'Telephoto', 'Infrared'].some(type =>
          device.label.toLowerCase().includes(type.toLowerCase())
        )
      );

      if (preferredCamera) {
        this.constraints.video.deviceId = { exact: preferredCamera.deviceId };
      } else if (videoDevices.length > 0) {
        this.constraints.video.deviceId = { exact: videoDevices[0].deviceId };
      }
    },
    activateScannerReserve() {
      this.initializeCamera().then(() => {
        this.showOptionsModal = false;
        this.reserve = true;
        this.return = false;
        this.restartScanner();
        this.showModal = true;
      });
    },
    activateScannerReturn() {
      this.initializeCamera().then(() => {
        this.showOptionsModal = false;
        this.return = true;
        this.reserve = false;
        this.restartScanner();
        this.showModal = true;
      });
    },
    restartScanner() {
      this.stopAllStreams();
      this.$nextTick(() => {
        this.scannerActive = true;
      });
    },
    onDecode(content) {
      this.decodedContent = content;
      if (this.reserve) {
        this.$emit('decodedReserve', content);
      }
      if (this.return) {
        this.$emit('decodedReturn', content);
      }

      this.reserve = false;
      this.return = false;
      this.closeModal();
    },
    closeModal() {
      this.stopAllStreams();
      this.showModal = false;
    },
    checkIfIphone() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isIphone = userAgent.includes('iPhone');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.big-icon {
  font-size: 25px;
}
.reserve-button {
  background: $vue;
}
</style>