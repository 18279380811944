import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

//MyTools
const MyTools = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/MyTools.vue');

//Tools
const Tools = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/Tools.vue');
const Tool = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/Tool.vue');

//Projects
const Projects = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Projects/Projects.vue');
const Project = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Projects/Project.vue');

//Locations
const Locations = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Locations/Locations.vue');

//Users
const Users = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Users/Users.vue');
const User = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Users/User.vue');

//Cars
const Cars = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Cars/Cars.vue');
const Car = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Cars/Car.vue');

//ToolGroup
const ToolGroup = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/ToolGroups/ToolGroup.vue');

//Settings
const AppSettings = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Settings/AppSettings.vue');
const CustomerSettings = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Settings/CustomerSettings.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import Widgets from 'src/pages/Widgets.vue';

// Analytics pages
const General = () => import('src/pages/Analytics/GeneralAnalytics.vue');
const CostReport = () => import('src/pages/Analytics/CostReport.vue');

// Confirm Registration
const ConfirmRegistration = () => import('src/pages/Registration/ConfirmRegistration.vue');

// Reset Password
const ResetPassword = () => import('src/pages/Registration/ResetPassword.vue');

// Forgot Password
const ForgotPassword = () => import('src/pages/Registration/ForgotPassword.vue');
// Maps pages - for later
// const VectorMaps = () =>
//   import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');
const LeafletMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/LeafletMaps.vue');

// Pages
const UserProfile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
// const TimeLine = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Documentation = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Documentation/Documentation.vue');

let analyticsMenu = {
  path: '/analytics',
  component: DashboardLayout,
  redirect: '/analytics/general',
  name: 'General',
  children: [
    {
      path: 'general',
      name: 'General',
      components: { default: General },
      meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
    },
    {
      path: 'map',
      name: 'Leaflet Map',
      components: { default: LeafletMaps },
      meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
    },
    {
      path: 'costreport',
      name: 'Cost Report',
      components: { default: CostReport },
      meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
    }
  ]
};

let settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/appsettings',
  name: 'Settings',
  children: [
    {
      path: 'appsettings',
      name: 'App Settings',
      components: { default: AppSettings },
      meta: { requiresAuth: true }  // This route requires authentication
    },
    {
      path: 'customersettings',
      name: 'Customer Settings',
      components: { default: CustomerSettings },
      meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
    }
  ]
};

let docsMenu = {
  path: '/docs',
  component: DashboardLayout,
  redirect: '/docs/introduction',
  name: 'Documentation',
  children: [
    {
      path: 'introduction',
      name: 'Documentation',
      components: { default: Documentation },
      meta: { requiresAuth: true }  // This route requires authentication
    },
    {
      path: ':page',
      name: 'Documentation',
      components: { default: Documentation },
      meta: { requiresAuth: true }  // This route requires authentication
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register
    // },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/confirm-registration',
      name: 'Confirm Registration',
      component: ConfirmRegistration
    },
    {
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    // {
    //   path: '/lock',
    //   name: 'Lock',
    //   component: Lock
    // }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/mytools',
    name: 'Home',
    meta: { requiresAuth: true }  // This route requires authentication
  },
  docsMenu,
  analyticsMenu,
  settingsMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/mytools',
    name: 'My Tools layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
      },
      {
        path: 'mytools',
        name: 'Mytools',
        components: { default: MyTools },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'tools',
        name: 'Tools',
        components: { default: Tools },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/tool/:toolId',
        name: 'Tool',
        components: { default: Tool },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'projects',
        name: 'Projects',
        components: { default: Projects },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/project/:projectId',
        name: 'Project',
        components: { default: Project },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'cars',
        name: 'Cars',
        components: { default: Cars },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/car/:toolGroupId',
        name: 'Car',
        components: { default: Car },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: '/toolgroup/:toolGroupId',
        name: 'ToolGroup',
        components: { default: ToolGroup },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'locations',
        name: 'Locations',
        components: { default: Locations },
        meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
      },
      {
        path: 'users',
        name: 'Users',
        components: { default: Users },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/user/:userId',
        name: 'User',
        components: { default: User },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'userprofile/:userId',
        name: 'User Profile Page',
        components: { default: UserProfile },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'userprofile',
        name: 'User Profile',
        components: { default: UserProfile },
        meta: { requiresAuth: true }  // This route requires authentication
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
