<template>
  <card class="stacked-form" :title="$t('tools.createTool')" :onCancel="cancel">
    <form @submit.prevent="saveTool">
      <div class="row">
        <div class="col-md-6">
            <label class="col-md-3 col-form-label">{{$t('common.name')}} *</label>
            <div class="col-md-9">
              <base-input :placeholder="$t('common.name')" ref="nameInput" v-model=name> </base-input>
            </div>

            <label class="col-md-3 col-form-label">{{$t('common.description')}} *</label>
            <div class="col-md-9">
              <base-input :placeholder="$t('common.description')" ref="descriptionInput" v-model=description> </base-input>
            </div>

            <label class="col-md-3 col-form-label">Serial</label>
            <div class="col-md-9">
              <base-input placeholder="Serial"  v-model=sku> </base-input>
            </div>

                <label class="col-md-3 col-form-label">{{$t('locations.location')}} *</label>
                <div class="col-md-9">
                  <el-select
                    ref="locationTypeInput"
                    :class="selectClass"
                    size="large"
                    :placeholder="$t('tools.selectLocation')"
                    v-model="locationOption"
                  >
                    <el-option
                      v-for="item in locationOptions"
                      :class="selectClass"
                      :value="item.address"
                      :label="item.address"
                      :key="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>

                <label class="col-md-3 col-form-label">{{$t('tools.selectType')}} *</label>
                <div v-if="toolTypeOption !== 'New Type'" class="col-md-9">
                  <el-select
                    ref="toolTypeInput"
                    :class="selectClass"
                    size="large"
                    :placeholder="$t('common.selectType')"
                    allow-create
                    v-model="toolTypeOption"
                  >
                    <el-option
                      v-for="item in toolTypeOptions"
                      :class="selectClass"
                      :value="item.type"
                      :label="item.type"
                      :key="item.id"
                    >
                    </el-option>
                    <el-option value="New Type">{{$t('common.addNewType')}}</el-option>
                  </el-select>
                </div>
                <div class="new-type" v-if="toolTypeOption === 'New Type'">
                  <div class="row">
                    <base-input
                    :placeholder="$t('tools.newToolType')" ref="newToolTypeInput" v-model=newToolType> </base-input>
                  <span class="cancel-new-type" @click="cancelNewToolType">
                    <i class="tim-icons icon-simple-remove"></i>
                  </span>
                  </div>
                </div>

            <label class="col-md-3 col-form-label">{{$t('tools.dailyPrice')}}</label>
            <div class="col-md-9 form-control-static">
              <base-input :placeholder="$t('tools.dailyPrice')"  v-model=dailyPrice> </base-input>
            </div>
            <label class="col-md-3 col-form-label">{{$t('tools.isle')}}</label>
            <div class="col-md-9 form-control-static">
              <base-input :placeholder="$t('tools.isle')"  v-model=isle> </base-input>
            </div>
            <label class="col-md-3 col-form-label">{{$t('tools.shelf')}}</label>
            <div class="col-md-9 form-control-static">
              <base-input :placeholder="$t('tools.shelf')"  v-model=shelf> </base-input>
            </div>

        </div>
        <div class="col-md-6">
           <div v-if="!isMobile" class="col-md-9">
              <image-upload :showUploadButton="false" @change="handleFileUpload" :select-text="$t('image.selectImage')" />
            </div>

            <label class="col-md-3 col-form-label">{{$t('tools.brand')}}</label>
            <div class="col-md-9">
              <base-input :placeholder="$t('tools.brand')"  v-model=brandName> </base-input>
            </div>

            <label class="col-md-3 col-form-label">{{$t('tools.maxReservationDays')}}</label>
            <div class="col-md-9">
              <base-input :placeholder="$t('tools.maxReservationDays')"  v-model=maxReservationDays> </base-input>
            </div>
            <label class="col-md-3 col-form-label">{{$t('tools.purchaseDate')}}</label>
            <div class="col-md-9">
              <base-input>
                <el-date-picker
                  type="date"
                  :placeholder="$t('tools.purchaseDate')"
                  v-model="purchaseDate"
                >
                </el-date-picker>
              </base-input>
          </div>
          <label class="col-md-3 col-form-label">{{$t('tools.purchasePrice')}}</label>
            <div class="col-md-9">
              <base-input :placeholder="$t('tools.purchasePrice')"  v-model=purchasePrice> </base-input>
            </div>
            <div class="col-md-9">
              <base-checkbox
                v-model="multiTool"
              >
                {{$t('tools.multiTool')}}
              </base-checkbox>
            </div>
          <label v-if="this.multiTool" class="col-md-3 col-form-label">{{$t('tools.totalCount')}}</label>
            <div class="col-md-9">
              <base-input v-if="this.multiTool" ref="totalCountInput"  :placeholder="$t('tools.totalCount')"  v-model=totalCount> </base-input>
            </div>

          <div v-if="isMobile" class="col-md-9">
            <image-upload :showUploadButton="false" @change="handleFileUpload" :select-text="$t('image.selectImage')" />
          </div>
          <div v-if="enableCustomQRBanner === true" class="col-md-9">
            <base-checkbox
              v-model="showQRBannerUpload"
            >
              {{$t('tools.customQRBanner')}}
            </base-checkbox>
          </div>
          <hr v-if="showQRBannerUpload && isMobile">
          <div v-if="showQRBannerUpload" class="col-md-9">
            <image-upload
              :showUploadButton="false"
              @change="handleQRBannerFileUpload"
              :src="bannerSrc"
              :select-text="$t('image.selectImage')"
            />
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <base-button class="mt-3" native-type="submit" type="standard" :disabled="isLoading">
            <span v-if="isLoading">
              <i class="fa fa-spinner fa-spin"></i> Saving...
            </span>
            <span v-else>
              {{$t('common.submit')}}
            </span>
          </base-button>
        </div>
      </div>
    </form>
  </card>
</template>
<script>

import api from "../../services/api"
import { DatePicker, Select, Option } from 'element-ui';
import { ImageUpload } from 'src/components/index';
import Cookies from 'js-cookie';


export default {
  data() {
    return {
      showQRBannerUpload: false,
      toolTypeOptions: [],
      locationOptions: [],
      toolTypeOption: "",
      locationOption: "",
      toolQRCodeBanner: null,
      name: "",
      description: "",
      toolTypeId: "",
      newToolType: "",
      toolImageBytes: "",
      purchaseDate: new Date().toISOString().slice(0, 19),
      brandName: "",
      dailyPrice: "",
      sku: "",
      maxReservationDays: "",
      isle: "",
      shelf: "",
      purchasePrice: "",
      multiTool: false,
      totalCount: "",
      isMobile: window.innerWidth <= 768,
      isLoading: false,
      enableCustomQRBanner: false
    };
  },
  watch: {
    showQRBannerUpload(newValue) {
      if (newValue === false) {
        this.toolQRCodeBanner = null
      }
    }
  },
   created() {
    if (!Cookies.get('enableCustomQRBanner')) {
      let url = "Customers/CustomerSettings?key=EnableCustomQRBanner";
      api.get(url)
        .then(response => {
          if (response.data.length > 0) {
            Cookies.set('enableCustomQRBanner', response.data[0].value === 'true');
            this.enableCustomQRBanner = response.data[0].value === 'true';
          }
          else {
            Cookies.set('enableCustomQRBanner', false);
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    } else {
    // If the cookie exists, set the component data property
        this.enableCustomQRBanner = Cookies.get('enableCustomQRBanner') === 'true';
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    bannerSrc() {
      return this.toolQRCodeBanner ? 'data:image/png;base64,' + this.toolQRCodeBanner : null;
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    ImageUpload
  },
  methods: {
    cancelNewToolType(){
      if(this.toolTypeOptions.length>0){
        this.toolTypeOption = this.toolTypeOptions[0].type
      }
      else{
        this.toolTypeOption = ''
      }
    },
    cancel() {
      this.$emit('close');
    },
    handleFileUpload(file) {
      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.toolImageBytes = btoa(binary);
        };
      reader.readAsArrayBuffer(file);
      }
      else{
        this.toolImageBytes = ""
      }

    },
    handleQRBannerFileUpload(file)
    {
      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.toolQRCodeBanner = btoa(binary);
        };
        reader.readAsArrayBuffer(file);
      }
      else{
        this.toolQRCodeBanner = null
      }
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.id} });
    },
    async saveTool() {
      this.isLoading = true;
      try {
        if (this.validateInput()) {
          let toolTypeId = 0

          if(this.newToolType !== '') {
            const newToolTypeResponse = await api.post('/ToolType', {
              type: this.newToolType,
            });
            toolTypeId = newToolTypeResponse.data.id;
            //Refresh tool types list:
            this.$emit('refreshToolTypesData');
          }
          else {
            toolTypeId = this.toolTypeOptions.filter(op => op.type === this.toolTypeOption)[0].id;
          }

          let locationId = this.locationOptions.filter(op => op.address === this.locationOption)[0].id;
          const response = await api.post('/Tool', {
            locationId: locationId,
            toolTypeId: toolTypeId,
            name: this.name,
            description: this.description,
            toolImageBytes: this.toolImageBytes,
            purchaseDate: this.purchaseDate,
            brandName: this.brandName,
            isle: this.isle,
            shelf: this.shelf,
            purchasePrice: this.purchasePrice === ""? 0: this.purchasePrice,
            dailyPrice:  this.dailyPrice === "" ? 0 : this.dailyPrice,
            sku: this.sku,
            maxReservationDays: this.maxReservationDays === "" ? 0 : this.maxReservationDays,
            totalCount: this.totalCount === "" ? null : Number(this.totalCount),
            multiTool: this.multiTool,
            toolQRCodeBanner: this.toolQRCodeBanner
          });

          this.$notify({
            message: this.$t('tools.toolCreated'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
          this.$emit('refreshData');
          this.$emit('close');
          //Removed this reroute to the actual tool after creation. Might be better to stay on Tools and create more.
          //
          //this.goToTool(response.data);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          message: this.$t('common.somethingWentWrong'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    fetchData()
    {
        let getToolTypesUrl = `/ToolType`
        api.get(getToolTypesUrl)
        .then(response => {
          this.toolTypeOptions = response.data;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });

        let getLocationsUrl = `/Location?pageSize=100`
        api.get(getLocationsUrl)
        .then(response => {
          this.locationOptions = response.data.result;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    validateInput() {
      let success = true;
      if (this.name === '') {
        this.$refs.nameInput.focus();
        this.$notify({
          message: this.$t('errors.nameEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.description === '') {
        this.$refs.descriptionInput.focus();
        this.$notify({
          message: this.$t('errors.descriptionEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolImageBytes === '') {
        this.$notify({
          message: this.$t('errors.imageEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.locationOption === '') {
        this.$refs.locationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.locationEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === 'New Type' && this.newToolType === '') {
        this.$refs.newToolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newToolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.multiTool && (this.totalCount === 0 || this.totalCount === '' || !this.checkIfNumber(this.totalCount))) {
        this.$refs.totalCountInput.focus();
        this.$notify({
          message: this.$t('errors.totalCountEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === '') {
        this.$refs.toolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.toolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
    },
    checkIfNumber(input) {
      if (!isNaN(input)) {
        return true
      } else {
        return false
      }
    }
  }
};
</script>
<style>
  .cancel-new-type {
  padding-left: 10px; /* Adjust as needed */
  padding-top: 10px;  /* Adjust as needed */
}
  .new-type {
  padding-left: 30px; /* Adjust as needed */
}</style>
