<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div
      class="card-header"
      v-if="$slots.header || title"
      :class="headerClasses"
    >
      <div class="header-content">
        <slot name="header">
          <h3 class="card-title" style="min-width: 200px;">{{ title }}</h3>
          <span v-if="onCancel" class="col-sm d-flex justify-content-end" @click.stop="onCancel">
            <i class="tim-icons icon-simple-remove"></i>
          </span>
          <span v-if="onAddObject" class="col-sm d-flex justify-content-end" @click.stop="onAddObject">
            <i class="tim-icons icon-simple-add big-icon"></i>
          </span>
          <span v-if="onCustomMethod" class="col-sm d-flex justify-content-end" @click.stop="onCustomMethod">
            <img v-if="customMethodImage !== ''" :src="customMethodImage" alt="app-logo" class="erp-logo"/>
            <i :class="[`${customMethodIcon}`]" class="big-icon"></i>
          </span>
          <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
        </slot>
        <slot name="header-extra"></slot> <!-- New slot for additional header content -->
        <slot v-if="!isMobile" name="table-select" class="col-sm d-flex justify-content-end"></slot> <!-- New slot for table selection buttons -->
      </div>
      <slot v-if="isMobile" name="table-select" class="col-sm d-flex justify-content-end"></slot> <!-- New slot for table selection buttons -->
    </div>
    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-image" v-if="$slots['image-bottom']">
      <slot name="image-bottom"></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      description: "Card title",
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
    },
    type: {
      type: String,
      description: "Card type (e.g primary/danger etc)",
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes",
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes",
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes",
    },
    onCancel: {
      type: Function,
      default: null,
    },
    onAddObject: {
      type: Function,
      default: null,
    },
    onCustomMethod: {
      type: Function,
      default: null,
    },
    customMethodIcon: {
      type: String,
      default: '',
    },
    customMethodImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768
    }
  },
  created() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
  },
  methods: {
    handleCancel() {
      if (this.onCancel) {
        this.onCancel();
      }
    },
    handleAddObject() {
      if (this.onAddObject) {
        this.onAddObject();
      }
    },
    handleCustomMethod() {
      if (this.onCustomMethod) {
        this.onCustomMethod();
      }
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>
<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.big-icon {
  font-size: 25px;
}
.erp-logo
{
  height: 25px;
  padding-right: 5px;
}
</style>
