<template>
    <card :title="$t('projects.createProject')" :onCancel="cancel">
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('projects.key')}} *</label>
          <base-input
            ref="keyInput"
            v-model="key"
            :placeholder="$t('projects.key')"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('common.status')}} *</label>
          <el-select
            ref="projectStatusInput"
            :class="selectClass"
            size="medium"
            style="width: 365px;"
            :placeholder="$t('common.status')"
            v-model="selectedOption"
          >
            <el-option
              v-for="item in statusOptions"
              :class="selectClass"
              :value="item.status"
              :label="item.status"
              :key="item.id"
            >
            </el-option>
          </el-select>
        </div>

      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('common.name')}} *</label>
          <base-input
            v-model="name"
            :placeholder="$t('common.name')"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('common.description')}}</label>

          <base-input
            v-model="description"
            :placeholder="$t('common.description')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">

        <div class="col-sm d-flex justify-content-end">
          <base-button @click="saveProject" type="standard" fill>{{$t('common.submit')}}</base-button>
        </div>
      </div>
    </card>
  </template>
  <script>
  import api from "../../services/api"
  import {Select, Option } from 'element-ui';


  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    props: {
      editMode: {
        type: Boolean,
        required: true
      },
      keyProp: {
        type: String,
        required: false
      },
      nameProp: {
        type: String,
        required: false
      },
      descriptionProp: {
        type: String,
        required: false
      },
      editingProjectId: {
        type: String,
        required: false
      },
      selectedProjectStatusOptionProp: {
        type: String,
        required: false
      }
    },
    computed: {
      selectClass() {
        let darkMode = localStorage.getItem('darkMode');
        // If darkMode does not exist in localStorage, default it to 'false'
        if (darkMode === null) {
          darkMode = 'false';
        }
        if (darkMode === 'false') {
          return 'select-standard';
        } else {
          return 'select-primary';
        }
      }
    },
    data() {
      return {
        selectedOption: this.selectedProjectStatusOptionProp,
        description: this.descriptionProp,
        name: this.nameProp,
        key: this.keyProp,

        statusOptions:[
          {id: 1, status: this.$t('projectStatus.notStarted') },
          {id: 2, status: this.$t('projectStatus.active') },
          {id: 3, status: this.$t('projectStatus.onHold') },
          {id: 4, status: this.$t('projectStatus.finished') },
        ],
      };
    },
    methods: {
      cancel()
      {
        this.$emit('close');
      },
      async saveProject() {
        if (this.validateInput()) {
          if(this.name === ''){
            this.name = null
          }
          if(this.description === ''){
            this.description = null
          }

          if(this.editMode)
          {
            const response = await api.put('/Project/' + this.editingProjectId, {
              key: this.key,
              name: this.name,
              description: this.description,
              projectStatusId: this.statusOptions.filter(op => op.status === this.selectedOption)[0].id
              }).then(request => {
                  this.$notify({
                    message: this.$t('projects.projectUpdated'),
                    icon: "tim-icons icon-check-2",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                    timeout: 1500,
                  });

                  this.$emit('refreshData');
                  this.$emit('close');
              }).catch(error => {
                console.error(error)
                this.$notify({
                  message: `Unable to create project: ${error.message}`,
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
            });

          }
          else
          {
            const response = await api.post('/Project', {
              key: this.key,
              name: this.name,
              description: this.description,
              projectStatusId: this.statusOptions.filter(op => op.status === this.selectedOption)[0].id
              }).then(request => {
                  this.$notify({
                    message: this.$t('projects.projectCreated'),
                    icon: "tim-icons icon-check-2",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                    timeout: 1500,
                  });

                  this.$emit('refreshData');
                  this.$emit('close');
              }).catch(error => {
                console.error(error)
                this.$notify({
                  message: `Unable to create project: ${error.message}`,
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
            });
          }


        }
      },
      validateInput() {
        let success = true;
        if (this.key === '') {
          this.$refs.keyInput.focus();
          this.$notify({
            message: this.$t('errors.keyEmpty'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "info",
            timeout: 2500,
          });
          success = false;
        }

        if (this.name === '') {
          this.$refs.keyInput.focus();
          this.$notify({
            message: this.$t('errors.nameEmpty'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "info",
            timeout: 2500,
          });
          success = false;
        }
        return success;
      }
    }
  };
  </script>
  <style></style>
