var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.mytools'),
          icon: 'tim-icons icon-shape-star',
          path: '/mytools',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.tools'),
          icon: 'tim-icons icon-settings',
          path: '/tools',
        }}}),(_vm.userRole !== 'User')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.cars'),
          icon: 'tim-icons icon-bus-front-12',
          path: '/cars',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.projects'),
          icon: 'tim-icons icon-bullet-list-67',
          path: '/projects',
        }}}),(_vm.userRole !== 'User')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.locations'),
          icon: 'tim-icons icon-square-pin',
          path: '/locations',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.users'),
          icon: 'fa fa-users',
          path: '/users',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.userProfile'),
          icon: 'tim-icons icon-badge',
          path: '/userProfile',
        }}}),(_vm.userRole !== 'User')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.generalAnalytics'),
          icon: 'tim-icons icon-sound-wave',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.analytics'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/analytics/general',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.costReport'),
            icon: 'tim-icons icon-money-coins',
            path: '/analytics/costreport',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.leafletMaps'),
            icon: 'tim-icons icon-world',
            path: '/analytics/map',
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.settings'),
          icon: 'tim-icons icon-settings-gear-63'
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.appsettings'),
            icon: 'tim-icons icon-palette',
            path: '/settings/appsettings'
          }}}),(_vm.userRole !== 'User' && _vm.userRole !== 'WarehouseUser')?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.customersettings'),
            icon: 'tim-icons icon-lock-circle',
            path: '/settings/customersettings'
          }}}):_vm._e()],1)],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }