<!-- QrScanner.vue -->
<template>
  <div>
    <div class="col-sm d-flex align-left">
        <button type="button" class="btn btn-info" @click="showOptionsModal = true">
        <i class="fas fa-camera fa-2x" ></i>
        </button>
    </div>
    <modal ref="modal" :show="showModal" @update:show="showModal = $event">
      <qrcode-stream
        v-if="scannerActive && !isIphone"
        ref="qrcodeStream"
        @decode="onDecode"
        :constraints="{ video: {
          facingMode: 'environment',
          zoom: 1,
          focusMode: ['continuous'],
          width: { ideal: 1280 },
          height: { ideal: 720 },
          aspectRatio: { ideal: 1.7777777778 }
          } }"
      ></qrcode-stream>
      <StreamBarcodeReader v-if="scannerActive && isIphone"
      ref="barcodeReader"
      @decode="onDecode"
      ></StreamBarcodeReader>
    </modal>

    <modal :show.sync="showOptionsModal" class="white-content">
      <h3 class="card-title" >{{$t('tools.ReserveOrUnreserve')}}</h3>
      <div class="row">
        <div class="col-sm">
          <base-button block class="reserve-button" @click="this.activateScannerReserve">{{$t('tools.reserve')}}</base-button>
        </div>
        <div class="col-sm">
          <base-button block type="danger" @click="this.activateScannerReturn">{{$t('tools.unreserve')}}</base-button>
        </div>
    </div>
  </modal>

  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader"
import { Modal } from "@/components"
import { StreamBarcodeReader } from 'vue-barcode-reader';


export default {
  components: {
    StreamBarcodeReader,
    QrcodeStream,
    Modal,
  },
  data () {
    return {
      isIphone: false,
      scannerVisible: false,
      scannerActive: false,
      showModal: false,
      showOptionsModal: false,
      decodedContent: '',
      reserve: false,
      return: false,
      constraints: {
        video: {
          facingMode: 'environment',
          zoom: 1,
          focusMode: ['continuous'],
          width: { ideal: 1280 },
          height: { ideal: 720 },
          aspectRatio: { ideal: 1.7777777778 }
        }
      }
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.checkIfIphone();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopScanner(); // Call stopScanner when component is about to be destroyed
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        this.stopScanner();
      } else {
        if (this.scannerVisible) {
          this.initializeCamera();
        }
      }
    },
    stopScanner() {
      if (this.$refs.barcodeReader && this.$refs.barcodeReader.$el) {
        const stream = this.$refs.barcodeReader.$el.srcObject;
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          this.$refs.barcodeReader.$el.srcObject = null;
        }
        this.scannerActive = false;
      }
      if (this.$refs.qrcodeStream && this.$refs.qrcodeStream.$el) {
        const stream = this.$refs.qrcodeStream.$el.srcObject;
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          this.$refs.qrcodeStream.$el.srcObject = null;
        }
        this.scannerActive = false;
      }
    },
    restartCamera() {
      this.stopScanner();
      this.$nextTick(() => {
        this.scannerActive = true;
      });
    },
    async initializeCamera() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      // Filter out specific camera types
      const preferredCamera = videoDevices.find(device =>
        !['Ultra Wide', 'Telephoto', 'Infrared'].some(type =>
          device.label.toLowerCase().includes(type.toLowerCase())
        )
      );

      if (preferredCamera) {
        console.log('Selected camera:', preferredCamera.label);
        this.constraints.video.deviceId = { exact: preferredCamera.deviceId };
      } else {
        if (videoDevices.length > 0) {
          const defaultCamera = videoDevices[0]; // Assuming the first one as default
          console.log('No preferred camera found, using default:', defaultCamera.label, ' - Device ID:', defaultCamera.deviceId);
        } else {
          console.warn('No camera devices found');
        }
      }
    },
    activateScannerReserve() {
      this.initializeCamera().then(() => {
        this.showOptionsModal = false;
        this.reserve = true;
        this.return = false;
        this.restartCamera();
        this.showModal = true;
      });
    },
    activateScannerReturn() {
      this.initializeCamera().then(() => {
        this.showOptionsModal = false;
        this.return = true;
        this.reserve = false;
        this.restartCamera();
        this.showModal = true;
      });
    },
    onDecode (content) {
      this.decodedContent = content;
      if(this.reserve === true){
        this.$emit('decodedReserve', content);  // Emit an event with the decoded content
      }
      if(this.return === true){
        this.$emit('decodedReturn', content);  // Emit an event with the decoded content
      }

      this.reserve = false;
      this.return = false;
      this.scannerActive = false;  // toggle the scanner when the decode has happened
      this.showModal = false;
      this.stopScanner();
      navigator.mediaDevices.getUserMedia({ video: false }).then(stream => {
        stream.getTracks().forEach(track => track.stop());
      });
      this.$refs.modal.closeModal();  // Close the modal
    },
    checkIfIphone() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Check if the user agent string contains "iPhone" without using regular expression
      //console.log(userAgent)
      this.isIphone = userAgent.includes("iPhone");
    },
  }
}
</script>

<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.big-icon {
  font-size: 25px;
}
.reserve-button{
  background: $vue;
}
</style>
