var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm d-flex align-left"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){_vm.showOptionsModal = true}}},[_c('i',{staticClass:"fas fa-camera fa-2x"})])]),_c('modal',{ref:"modal",attrs:{"show":_vm.showModal},on:{"update:show":function($event){_vm.showModal = $event},"close":_vm.closeModal}},[(_vm.scannerActive && !_vm.isIphone)?_c('qrcode-stream',{ref:"qrcodeStream",attrs:{"constraints":{ video: {
        facingMode: 'environment',
        zoom: 1,
        focusMode: ['continuous'],
        width: { ideal: 1280 },
        height: { ideal: 720 },
        aspectRatio: { ideal: 1.7777777778 }
        } }},on:{"decode":_vm.onDecode}}):_vm._e(),(_vm.scannerActive && _vm.isIphone)?_c('StreamBarcodeReader',{ref:"barcodeReader",on:{"decode":_vm.onDecode}}):_vm._e()],1),_c('modal',{staticClass:"white-content",attrs:{"show":_vm.showOptionsModal},on:{"update:show":function($event){_vm.showOptionsModal=$event}}},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('tools.ReserveOrUnreserve')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('base-button',{staticClass:"reserve-button",attrs:{"block":""},on:{"click":_vm.activateScannerReserve}},[_vm._v(_vm._s(_vm.$t('tools.reserve')))])],1),_c('div',{staticClass:"col-sm"},[_c('base-button',{attrs:{"block":"","type":"danger"},on:{"click":_vm.activateScannerReturn}},[_vm._v(_vm._s(_vm.$t('tools.unreserve')))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }