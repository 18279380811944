<template>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12">
        <create-car-form v-if="showForm"
          @close="closeForm"
          @refreshData="fetchData"
          :editMode="this.editMode"
          :nameProp="String(this.name)"
          :licencePlateProp="String(this.licencePlate)"
          :toolGroupImageBytesProp="String(this.imageBytes)"
          :carIdProp="String(this.carId)"
        >
        </create-car-form>
      </div>
      <card card-body-classes="table-full-width" :title="$t('cars.cars')" :onAddObject="openForm">
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <base-input>
              <el-input
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                :placeholder="$t('common.searchRecords')"
                v-model="searchQuery"
                aria-controls="datatables"
                @keyup.native="search"
                @clear="handleClearSearch"
              >
              </el-input>
            </base-input>
          </div>
          <el-base-table
            :tableData="cars"
            :tableColumns="tableColumns"
            :enableEdit="true"
            :enableDelete="true"
            :actionsInline="true"
            :allowClick="true"
            @row-click="goToCar"
            @edit="handleEdit"
            @delete="handleDelete" />
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import api from "../../services/api"
import CreateCarForm from "./CreateCarForm";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import ElBaseTable from '../../components/ElBaseTable.vue';
import swal from 'sweetalert2';
import Cookies from 'js-cookie';



export default {
components: {
    CreateCarForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-base-table': ElBaseTable
},

computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
  },
data() {
    return {
      showForm: false,
      editMode: false,
      name: '',
      licencePlate: '',
      imageBytes: '',
      carId: '',
      cars: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        { prop: 'licencePlate', label: this.$t('cars.licencePlate'), minWidth: 200 },
        { prop: 'numberOfTools', label: this.$t('cars.numberOfTools'), minWidth: 100 },
        { prop: 'reservedBy', label: this.$t('cars.reservedBy'), minWidth: 200 },
        { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true,
          compound: {
            'common.name': 'name', 'cars.licencePlate': 'licencePlate', 'cars.numberOfTools': 'numberOfTools'
          }
        }
      ],
      searchQuery: '',
      isMobile: window.innerWidth <= 768,
      initalPage: true
    };
},
methods: {
updatePage(item){
  this.pagination.currentPage = item;
  Cookies.set('carsPage', this.pagination.currentPage, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
  this.fetchData()
},
search(event){
  const queryLength = this.searchQuery.length;
  this.pagination.currentPage = 1;

  if (event.key === 'Backspace' || event.key === 'Enter') {
    this.fetchData();
  }
  else {
    if (queryLength > 1) {
      if (queryLength % 2 === 0) {
        this.fetchData();
      }
    }
  }
},
handleClearSearch()
{
    Cookies.remove('carsPage');
    this.pagination.currentPage = 1
    this.fetchData();
},
handleEdit(index, row) {
  this.name = row.name;
  this.licencePlate = row.licencePlate;
  this.carId = row.id;
  this.editMode = true;

  let toolGroupUrl = `/ToolGroup/${this.carId}`;
      api.get(toolGroupUrl)
        .then(response => {
          this.imageBytes = response.data.toolGroupImageBytes;
          this.openForm();
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
},
handleDelete(index, row){
  swal.fire({
    title: this.$t('delete.areYouSure'),
    text: this.$t('delete.noRevert'),
    icon: 'warning',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill'
    },
    confirmButtonText: this.$t('delete.yesDeleteIt'),
    cancelButtonText: this.$t('delete.cancel'),
    buttonsStyling: false
  }).then(result => {
    if (result.value) {
      this.deleteToolGroup(row);
    }
  });
},
deleteToolGroup(row)
{
  let deleteUrl = `/ToolGroup/` + row.id
  api.delete(deleteUrl)
  .then(response => {
    this.cars = this.cars.filter(car => car.id !== row.id);
    swal.fire({
      title: this.$t('delete.deleted'),
      text: `${this.$t('delete.youDeleted')} ${row.name}`,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success btn-fill',
      },
      buttonsStyling: false
    });
  })
  .catch(error => {
    console.error('API request error:', error);
    this.$notify({
      message: this.$t('common.unableToDelete'),
      icon: "tim-icons icon-alert-circle-exc",
      horizontalAlign: "center",
      verticalAlign: "top",
      type: "danger",
      timeout: 0,
    });
  });
},
openForm()
{
  this.showForm = true;
  this.$nextTick(() => {
    window.scrollTo(0, 0);
  });
},
closeForm()
{
  this.showForm = false;
  this.editMode = false;
  this.name = '';
  this.licencePlate = '';
  this.imageBytes = '';
},
goToCar(item) {
  this.$router.push({ name: 'Car', params: { toolGroupId: item.id.toString() } });
},
fetchData() {
    let getToolGroupUrl  = ''
    if(this.searchQuery === '')
    {
        Cookies.remove('carsSearchQuery');
        getToolGroupUrl = `/ToolGroup?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&toolGroupTypeId=1`
    }
    else
    {
      Cookies.set('carsSearchQuery', this.searchQuery, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
      getToolGroupUrl = `/ToolGroup?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&toolGroupTypeId=1&searchString=${this.searchQuery}`
    }
    api.get(getToolGroupUrl)
    .then(response => {
        this.pagination.total = response.data.totalItems;
        this.cars = response.data.result.map(item => {
          let numberOfTools = 0
          numberOfTools = item.toolToToolGroupsCount
          let reservedBy = ''
          if(item.toolGroupReservations.filter(p => p.isReserved === true) && item.toolGroupReservations.filter(p => p.isReserved === true).length > 0)
          {
            reservedBy = item.toolGroupReservations.filter(p => p.isReserved === true)[0].user.firstName + ' ' + item.toolGroupReservations.filter(p => p.isReserved === true)[0].user.lastName
          }
          let licencePlate = ''
          if(item.toolGroupProperties.filter(p => p.key === 'licencePlate'))
          {
            licencePlate = item.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
          }
          return {
              id: item.id,
              name: item.name,
              reservedBy: reservedBy,
              licencePlate: licencePlate,
              numberOfTools: numberOfTools
          };
        });
    })
    .catch(error => {
      console.error('API request error:', error);
      this.$notify({
        message: this.$t('common.unableToRetrieveData'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    });
    },
},
created() {
  if (Cookies.get('defaultPageSize'))
  {
    this.pagination.perPage = Number(Cookies.get('defaultPageSize'))
  }

  const savedSearchQuery = Cookies.get('carsSearchQuery');
  if (savedSearchQuery) {
    this.searchQuery = savedSearchQuery;
  } else {
    this.searchQuery = '';
  }

  const savedPage = Cookies.get('carsPage');
  if (savedPage) {
    this.pagination.currentPage = Number(savedPage);
  } else {
    this.pagination.currentPage = 1
  }

  this.fetchData();
},
};
</script>
<style></style>
