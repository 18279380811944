<template>
	<div>
		<div class="row">
			<div class="col-md-12 mr-auto ml-auto" v-if="costRegistrationLoaded">
				<card :title="$t('sidebar.costReport')">
					<base-input v-if="!showProjectDetails">
						<el-input
							type="search"
							class="mb-3 search-input from-group"
							clearable
							prefix-icon="el-icon-search"
							:placeholder="$t('common.searchRecords')"
							v-model="searchQuery"
							aria-controls="datatables"
						>
						</el-input>
					</base-input>
					<div class="calendar d-flex align-items-center">
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								v-model="fromDate"
								@change="handleDateChange"
							>
							</el-date-picker>
						</div>
						<span class="mx-2">-</span>
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								v-model="toDate"
								@change="handleDateChange"
							>
							</el-date-picker>
						</div>
					</div>
					<template>
						<div v-if="!showProjectDetails">
							<div v-if="!isMobile">
								<el-table  :data="groupedReservations.sortedGrouped" @row-click="expandProject">
									<el-table-column prop="name" :label="$t('common.project')" min-width="150"/>
									<el-table-column prop="totalRevenue" :label="$t('common.totalAmount')" min-width="50" />
									<el-table-column prop="totalRevenueWithVAT" :label="$t('common.totalAmountVAT')" min-width="50"/>
								</el-table>
								<el-table v-if="groupedReservationsCalculated" :data="groupedReservations">
									<el-table-column :label="$t('common.totalAmount')" min-width="150" />
									<el-table-column :label="groupedReservations.totalAmount.toString()" min-width="50" />
									<el-table-column :label="groupedReservations.totalAmountWithVAT.toString()" min-width="50"/>
								</el-table>
							</div>
							<div v-else>
								<el-table :data="groupedReservations.sortedGrouped" @row-click="expandProject" >
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('common.project')}}:</strong> {{ scope.row.name }}</div>
												<div><strong>{{$t('common.totalAmount')}}:</strong> {{ scope.row.totalRevenue }}</div>
												<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ scope.row.totalRevenueWithVAT }}</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div><strong>{{$t('common.totalAmount')}}:</strong> {{ groupedReservations.totalAmount }}</div>
								<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ groupedReservations.totalAmountWithVAT }}</div>
							</div>
						</div>

						<div v-if="showProjectDetails">
							<card :title="expandedProject.name" :onCancel="collapseProject">
								<div class="project-header">
									<h5>{{ $t('common.totalAmount') }} : {{ expandedProject.totalRevenue }} kr</h5>
									<h5>{{ $t('common.totalAmountVAT') }} : {{ expandedProject.totalRevenueWithVAT }} kr</h5>
								</div>
								<el-table v-if="!isMobile" :data="flattenedReservationsComp">
									<el-table-column prop="tool.name" :label="$t('tools.tools')" />
									<el-table-column 
										:label="$t('tools.reservation')"
										:formatter="formatDates"
									>
										<template slot-scope="scope">
											<div>
												{{ new Date(scope.row.reservedTimestamp).toLocaleDateString() }} - {{ new Date(scope.row.unreservedTimestamp).toLocaleDateString() }}
											</div>
											<div v-if="scope.row.rented">
												{{ $t('tools.rented') }}: {{ scope.row.externalUser }}
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="dailyPrice" :label="$t('tools.dailyPrice')" />
									<el-table-column :label="$t('common.amount')">
										<template slot-scope="scope">
											<div v-if="!scope.row.totalPriceOverridden">{{ scope.row.totalPriceWithoutVAT }}</div>
											<div v-else class="text-danger">{{ scope.row.totalPriceWithoutVAT }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :label="$t('common.amountVAT')">
										<template slot-scope="scope">
											<div v-if="!scope.row.totalPriceOverridden">{{ scope.row.totalPrice }}</div>
											<div v-else class="text-danger">{{ scope.row.totalPrice }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :min-width="100" align="right" :label="$t('common.actions')">
										<div slot-scope="props">
											<base-button @click.native.stop="handleReservationEdit(props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
												<i class="tim-icons icon-pencil"></i>
											</base-button>
										</div>
									</el-table-column>
								</el-table>
								<el-table v-else :data="flattenedReservationsComp">
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('tools.tools')}}:</strong> {{ scope.row.tool.name }}</div>
												<div><strong>{{$t('tools.reservation')}}:</strong> {{ new Date(scope.row.reservedTimestamp).toLocaleDateString() }} - {{ new Date(scope.row.unreservedTimestamp).toLocaleDateString() }}</div>
												<div v-if="scope.row.rented"><strong>{{$t('tools.rentedTo')}}:</strong> {{ scope.row.externalUser }}</div>
												<div><strong>{{$t('tools.dailyPrice')}}:</strong> {{ scope.row.dailyPrice }}</div>
												<template v-if="!scope.row.totalPriceOverridden">
													<div><strong>{{$t('common.totalAmount')}}:</strong> {{ scope.row.totalPriceWithoutVAT }}</div>
													<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ scope.row.totalPrice }}</div>
												</template>
												<template v-else>
													<div class="text-danger"><strong>{{$t('common.totalAmount')}}:</strong> {{ scope.row.totalPriceWithoutVAT }}*</div>
													<div class="text-danger"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ scope.row.totalPrice }}*</div>
													<div class="text-danger"><strong>{{$t('common.updated')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												
												<div>
													<base-button
														@click.native.stop="handleReservationEdit(scope.$index, scope.row)"
														class="edit btn-link"
														type="warning"
														size="sm"
														icon
													>
														<i class="tim-icons icon-pencil"></i>
													</base-button>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<base-button class="download-button" type="standard" @click="generatePDF(expandedProject)">
									{{ $t('common.download') }}
								</base-button>
							</card>
						</div>
					</template>
					<modal :show.sync="showUpdateReservationDaysModal" class="white-content">
						<h3 class="card-title">{{$t('tools.setNumberOfDays')}}</h3>
						<div class="row">
							<div class="col-12">
							<base-input
							:label="$t('tools.numberOfDays')"
							:placeholder="$t('tools.updatedNumberOfDays')"
							v-model="updateDays"
							></base-input>
							</div>
						</div>
						<div class="row">
							<div class="col-sm d-flex justify-content-end">
							<base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
							</div>
						</div>
					</modal>
				</card>
			</div>
		</div>
	</div>
</template>
<script>

import api from "../../services/api"
import { Table, TableColumn, DatePicker } from 'element-ui';
import { Modal } from 'src/components';
import jsPDF from 'jspdf';
import swal from 'sweetalert2';

export default {
	components: {
		[DatePicker.name]: DatePicker,
		[Table.name]: Table,
    	[TableColumn.name]: TableColumn,
		Modal
	},
	data() {
		return {
			activeNames: ['1'],
			toDate: null,
			fromDate: null,
			groupedProjects: [],
			reservationData: [],
			customerInformation: {},
			costRegistrationLoaded: false,
			groupedReservationsCalculated: false,
			searchQuery: '',
			isMobile: window.innerWidth <= 768,
			showProjectDetails: false,
			showUpdateReservationDaysModal: false,
			updateDays: '',
			latestUnreservedId: '',
			latestUnreservedEntity: 'ToolReservation',
			expandedProject: null,
			activeIndex: ''
		};
	},
	computed: {
		groupedReservations() {
			let grouped = this.reservationData.reduce((grouped, reservation) => {
				let projectId = reservation.project?.id || "reservationsNotLinkedToProject";
				let toolId = reservation.tool.id;
				if (!grouped[projectId]) {
					grouped[projectId] = {};
				}
				if (!grouped[projectId][toolId]) {
					grouped[projectId][toolId] = [];
				}
				grouped[projectId][toolId].push(reservation);
				return grouped;
			}, {});

			let totalAmount = 0;
			let totalAmountWithVAT = 0;

			for (let projectId in grouped) {
				let projectName = null;
				let projectKey = null;
				let projectTotalRevenue = 0;
				let tools = [];

				for (let toolId in grouped[projectId]) {
					let reservations = grouped[projectId][toolId];
					projectName = reservations[0].project ? reservations[0].project.name : this.$t('tools.rented');
					projectKey = reservations[0].project ? reservations[0].project.key : '';
					let totalRevenue = reservations.reduce((total, reservation) => total + reservation.totalPrice, 0);
					tools.push({
						toolId: toolId,
						reservations: reservations,
						totalRevenue: this.formatNumber((totalRevenue / 1.24)),
						totalRevenueWithVAT: this.formatNumber(totalRevenue)
					});
					projectTotalRevenue += totalRevenue;
				}

				grouped[projectId] = {
					tools: tools,
					totalRevenue: this.formatNumber((projectTotalRevenue / 1.24)),
					totalRevenueWithVAT: this.formatNumber(projectTotalRevenue),
					name: projectName,
					key: projectKey,
					projectId: projectId
				};

				totalAmount += (projectTotalRevenue / 1.24);
				totalAmountWithVAT += projectTotalRevenue;
			}

			let sortedGrouped = Object.keys(grouped)
				.sort((a, b) => { 
				if (a === "reservationsNotLinkedToProject") return 1;
				if (b === "reservationsNotLinkedToProject") return -1;
				return grouped[a].name.localeCompare(grouped[b].name);
				})
				.reduce((sorted, key) => { sorted[key] = grouped[key]; return sorted; }, {});

			// Filter based on search query
			if (this.searchQuery) {
				sortedGrouped = Object.fromEntries(
				Object.entries(sortedGrouped).filter(([key, value]) => {
					if (value.name) {
					return value.name.toLowerCase().includes(this.searchQuery.toLowerCase());
					}
				})
				);
			}

			grouped = {
				sortedGrouped: Object.values(sortedGrouped),
				totalAmount: this.formatNumber(totalAmount),
				totalAmountWithVAT: this.formatNumber(totalAmountWithVAT)
			};
			this.groupedReservationsCalculated = true;
			return grouped;
		},
		flattenedReservationsComp() {
			if (!this.expandedProject || !this.expandedProject.tools) {
				return [];
			}
			var flattened = this.expandedProject.tools.flatMap(tool => 
				tool.reservations.map(reservation => ({
					...reservation,
					totalPriceWithoutVAT: this.formatNumber((reservation.totalPrice / 1.24)),
					dailyPrice: this.formatNumber(reservation.dailyPrice),
					totalPrice: this.formatNumber(reservation.totalPrice)
				}))
			);
			return flattened;
		},
		selectEditClass() {
			let darkMode = localStorage.getItem('darkMode');
			// If darkMode does not exist in localStorage, default it to 'false'
			if (darkMode === null) {
				darkMode = 'false';
			}
			if (darkMode === 'false') {
				return 'btn-standard';
			} else {
				return 'btn-primary';
			}
		}
	},
	created() {
		window.addEventListener('resize', this.updateIsMobile);
		this.initializeDateRange();
		this.fetchCostRegistration();
		this.fetchCustomerInformation();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateIsMobile);
	},
	methods: {
		handleReservationEdit(index, row) {
			if (row.totalPrice === null) {
				this.$notify({
					message: this.$t('errors.unableToUpdateLoanedTool'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "info",
					timeout: 3000,
				});
				return;
			}
			swal.fire({
				title: this.$t('tools.updateReservedDays'),
				text: `${this.$t('tools.toolReserved')} ${new Date(row.reservedTimestamp).toLocaleDateString('is-IS')} - ${new Date(row.unreservedTimestamp).toLocaleDateString('is-IS')} \n ${this.$t('common.totalAmount')} ${row.totalPrice} Kr.`,
				icon: 'success',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-danger btn-fill',
					cancelButton: 'btn btn-success btn-fill'
				},
				confirmButtonText: this.$t('tools.updateReservedDays'),
				cancelButtonText: this.$t('delete.cancel'),
				buttonsStyling: false
			}).then(result => {
				if (result.value) {
					this.latestUnreservedId = row.id;
					this.latestUnreservedEntity = row.rented ? 'ExternalReservation' : 'ToolReservation'
					this.showUpdateReservationDaysModal = true;
					this.activeIndex = index
				}
			});
		},
		updateReservationDays(){
			if (!Number.isInteger(Number(this.updateDays)) || this.updateDays === '') {
				this.$notify({
					message: this.$t('common.needsToBeNumber'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
				return;
			}

			// Default the URL value to standard tool reservation
			let url = `/Tool/Reservation/${this.latestUnreservedId}`;
			
			if (this.latestUnreservedEntity === 'ExternalReservation') {
				url = `/Tool/Reservation/External/${this.latestUnreservedId}`;
			}

			api.put(url,{days: this.updateDays}).then(response => {
				this.showUpdateReservationDaysModal = false;
				this.updateDays = ''
				swal.fire({
					title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
					icon: 'success',
					customClass: {
						confirmButton: 'btn btn-success btn-fill'
					},
					buttonsStyling: false
				});

				// Update the specific reservation within expandedProject 
				let toolIndex = this.expandedProject.tools.findIndex(tool => tool.reservations.some(reservation => 
					reservation.id === this.latestUnreservedId) );
				
				if (toolIndex !== -1) {
					let reservationIndex = this.expandedProject.tools[toolIndex].reservations.findIndex(reservation => 
						reservation.id === this.latestUnreservedId );
					if (reservationIndex !== -1) {
						this.expandedProject.tools[toolIndex].reservations[reservationIndex].totalPrice = response.data.totalPrice;
						this.expandedProject.tools[toolIndex].reservations[reservationIndex].totalPriceOverridden = true;

						// Recalculate totals
						let projectTotalRevenue = 0;
						let projectTotalRevenueWithVAT = 0;
						this.expandedProject.tools.forEach(tool => {
							tool.reservations.forEach(reservation => {
								projectTotalRevenue += reservation.totalPrice / 1.24;
								projectTotalRevenueWithVAT += reservation.totalPrice;
							});
						});

						// Update the project totals
						this.expandedProject.totalRevenue = this.formatNumber(projectTotalRevenue);
						this.expandedProject.totalRevenueWithVAT = this.formatNumber(projectTotalRevenueWithVAT);
						
						// Trigger Vue reactivity by creating a new object
						this.expandedProject = { ...this.expandedProject }; 
					}
				}
			})
			.catch(error => {
				console.error('API request error:', error);
				this.$notify({
					message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
			});
		},
		formatNumber(num) {
			const roundedNumber = Math.round(num);
			const fixedNumber = roundedNumber.toFixed(2);
			const parts = fixedNumber.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		expandProject(row) {
			this.expandedProject = row;
			this.showProjectDetails = true;
			window.scrollTo(0, 0);
		},
		collapseProject() {
			this.fetchCostRegistration();
			this.expandedProject = null;
			this.showProjectDetails = false;
		},
		initializeDateRange() {
			const today = new Date();
			this.toDate = today;
			this.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
		},
		fetchCostRegistration() {
			const fetchReservations = async (url) => {
				let allReservations = [];
				let currentPage = 1;
				let totalPages = 1;

				while (currentPage <= totalPages) {
					const response = await api.get(`${url}&currentPage=${currentPage}`);
					const data = response.data;

					allReservations = allReservations.concat(data.result.filter(item => item.totalPrice !== 0 && item.totalPrice !== null));

					currentPage = data.currentPage + 1;
					totalPages = data.totalPages;
				}
				return allReservations;
			};
			
			let start = this.formatDate(this.fromDate);
			let end = this.formatDate(this.toDate);
			const reservationUrl = `/Tool/Reservations?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			const externalReservationUrl = `/Tool/Reservations/External?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			Promise.all([fetchReservations(reservationUrl), fetchReservations(externalReservationUrl)])
				.then(([internalReservations, externalReservations]) => {
					this.reservationData = internalReservations.concat(externalReservations.filter(item => item.rented));
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: `Unable to fetch reservations`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				})
				.finally(() => {
					this.costRegistrationLoaded = true;
					
					// This is a bit of a hack to trigger an update to the Project view when date ranges are modified
					if (this.showProjectDetails) {
						this.updateExpandedProject();
					}
				});
		},
		handleDateChange() {
			this.fetchCostRegistration();
		},
		updateExpandedProject() {
			let grouped = this.reservationData.reduce((grouped, reservation) => {
				let projectId = reservation.project?.id || "reservationsNotLinkedToProject";
				let toolId = reservation.tool.id;
				if (!grouped[projectId]) {
					grouped[projectId] = {};
				}
				if (!grouped[projectId][toolId]) {
					grouped[projectId][toolId] = [];
				}
				grouped[projectId][toolId].push(reservation);
				return grouped;
			}, {});
			for (let projectId in grouped) {
				let projectName = null;
				let projectKey = null;
				let projectTotalRevenue = 0;
				let tools = [];
				for (let toolId in grouped[projectId]) {
					let reservations = grouped[projectId][toolId];
					projectName = reservations[0].project ? reservations[0].project.name : this.$t('tools.rented');
					projectKey = reservations[0].project ? reservations[0].project.key : '';
					let totalRevenue = reservations.reduce((total, reservation) => total + reservation.totalPrice, 0);
					tools.push({
						toolId: toolId,
						reservations: reservations,
						totalRevenue: (totalRevenue / 1.24).toFixed(0),
						totalRevenueWithVAT: totalRevenue
					});
					projectTotalRevenue += totalRevenue;
				}

				grouped[projectId] = {
					tools: tools,
					totalRevenue: (projectTotalRevenue / 1.24).toFixed(0),
					totalRevenueWithVAT: projectTotalRevenue,
					name: projectName,
					key: projectKey,
					projectId: projectId
				};
			}

			if (grouped[this.expandedProject.projectId] === undefined) {
				this.expandedProject.tools = [];
			}
			else {
				this.expandedProject = grouped[this.expandedProject.projectId];
			}
		},
		formatDate(date) {
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		formatDates(reservation) {
			if (reservation && reservation.reservedTimestamp && reservation.unreservedTimestamp) {
				return `${new Date(reservation.reservedTimestamp).toLocaleDateString()} - ${new Date(reservation.unreservedTimestamp).toLocaleDateString()}`;
			} 
			else {
				return '';
			}
		},
		formatPrice(price) {
			return price ? price.toFixed(2) : '0.00';
		},
		fetchCustomerInformation() {
			let customerUrl = `/Customers/Me`
			api.get(customerUrl)
				.then(response => {
					this.customerInformation = response.data.value;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		generatePDF(project) {
			const doc = new jsPDF();
			doc.setFont('times', 'normal');
			const textXPosition = 65;

			// This whole if else if crap should be revisited, is a bit retarded how I am doing this now tbh
			if (this.customerInformation.logoBytes === null && this.customerInformation.identificationNumber === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(project.name, textXPosition, 30);
				doc.text(project.key, textXPosition, 40);
				generatePdfBody(project, doc);
			}
			else if (this.customerInformation.logoBytes === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(project.name, textXPosition, 40);
				doc.text(project.key, textXPosition, 50);
				this.generatePdfBody(project, doc);
			}
			else if (this.customerInformation.identificationNumber === null) {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(project.name, textXPosition, 30);
					doc.text(project.key, textXPosition, 40);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
			else {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
					doc.setFontSize(10);
					doc.text(project.name, textXPosition, 40);
					doc.text(project.key, textXPosition, 50);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
		},
		generatePdfBody(project, doc) {
			doc.setFontSize(10);
			doc.line(10, 72, 200, 72); // Draw a line

			// Headers
			const headers = ["Tól", "Verð", "Verð m/vsk"];
			const startX = 10;
			let currentYPosition = 80;
			// Adjust this for spacing between headers 
			headers.forEach((header, index) => {
				const headerPositions = [startX, startX + 120, startX + 160];
				doc.text(header, headerPositions[index], currentYPosition); 
			});

			doc.line(10, currentYPosition + 5, 200, currentYPosition + 5); 
			// Line below headers
			currentYPosition += 15;

			// Starting Y position for the text
			// let currentYPosition = 80; 
			const pageHeight = doc.internal.pageSize.height;
			const bottomMargin = 10; // Margin at the bottom of the page

			// Add the information from the collapse item
			// You'll need to format this content appropriately
			const projectInfo = this.formatProjectInfoForPDF(project);
			const lines = projectInfo.split('\n');

			// Add each line of the project info to the PDF
			lines.forEach(line => {
				// Check if the line is not a placeholder for the horizontal line
				if (line !== '---') {
					// Check if the current position exceeds the page height
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.text(line, 10, currentYPosition);
					currentYPosition += 5; // Increase Y position for the next line
				} 
				else {
					// Ensure there's enough space for the line
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.line(10, currentYPosition - 5, 200, currentYPosition - 5); // Draw a line
					currentYPosition += 5; // Increase Y position for the next line
				}
			});

			// Save the PDF
			doc.save(`${project.name}.pdf`);
		},
		formatProjectInfoForPDF(project) {
			let formattedText = '';
			const pageWidth = 210; // A4 width in mm
			const rightMargin = 10; // Right margin in mm
			const doc = new jsPDF();

			// Set the font to Times New Roman
			doc.setFont('times', 'normal');
			doc.setFontSize(10);

			const pricePosition = 130;          // Align with the "Verð" header
			const priceWithVATPosition = 170;   // Align with the "Verð m/VSK" header

			for (let tool of project.tools) {
				// Tool name
				formattedText += `${tool.reservations[0].tool.name}\n`;

				// Reservations for the tool
				tool.reservations.forEach(reservation => {
					let reservedDate = new Date(reservation.reservedTimestamp).toLocaleDateString();
					let unreservedDate = new Date(reservation.unreservedTimestamp).toLocaleDateString();
					let priceCalculation = this.formatNumber((reservation.totalPrice / 1.24)) + " kr";
					let priceWithVAT = this.formatNumber(reservation.totalPrice) + " kr";
					let dates = `     ${reservedDate} - ${unreservedDate}`;

					// Adjust positions based on character lengths
					const priceTextWidth = doc.getStringUnitWidth(priceCalculation) * doc.internal.getFontSize() / doc.internal.scaleFactor;
					const priceWithVATTextWidth = doc.getStringUnitWidth(priceWithVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
					const adjustedPricePosition = pricePosition - priceTextWidth;
					const adjustedPriceWithVATPosition = priceWithVATPosition - adjustedPricePosition - priceWithVATTextWidth;

					// Print formatted line with proper alignment
					formattedText += `${dates.padEnd(adjustedPricePosition, ' ')}`
								+ `${priceCalculation.padEnd(adjustedPriceWithVATPosition + 5, ' ')}`
								+ `${priceWithVAT}\n`;
				});

				// Empty line between each tool
				formattedText += '\n';
			}

			// Placeholder for the line above the total revenue
			formattedText += '---\n';
			let revenueWithoutVAT = project.totalRevenue + " kr";
			let totalWithVAT = project.totalRevenueWithVAT + " kr";

			// Align totals with headers
			const totalTextWidthWithoutVAT = doc.getStringUnitWidth(revenueWithoutVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
			const totalTextWidthWithVAT = doc.getStringUnitWidth(totalWithVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
			const adjustedTotalPositionWithoutVAT = pricePosition - totalTextWidthWithoutVAT;
			const adjustedTotalPositionWithVAT = priceWithVATPosition - adjustedTotalPositionWithoutVAT - totalTextWidthWithVAT;

			formattedText += "Heildarupphæð     ".padEnd(adjustedTotalPositionWithoutVAT + 5, ' ') 
						+ revenueWithoutVAT.padEnd(adjustedTotalPositionWithVAT + 5, ' ') 
						+ totalWithVAT;

			return formattedText;
		},
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 768;
		}
	}
};
</script>
<style scoped>
	.cost-report { 
		width: 100%; border-collapse: collapse;
	}
	.header-row, .project-row, .footer-row, .details {
		display: flex;
	}
	.header-row {
		border-bottom: 1px solid #ddd;
	}
	.footer-row {
		border-top: 1px solid #ddd;
	}
	.cell { 
		flex: 1; padding: 8px; text-align: left; cursor: pointer;
	} 
	.tool-row .cell { 
		cursor: default;
	}
	.calendar {
		padding-bottom: 15px;
	}
	.item-tool {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.item-tool-dates {
		padding-left: 40px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.item-tool-dates-amount {
		float: right;
		padding-right: 50px;
	}

	.download-button {
		width: 100%
	}

	.project-details { 
		display: block;
	}
	.newline { 
		display: block;
	}
</style>
