<template>
  <div class="row" v-if="userInfoLoaded">
    <card>
      <div class="col-md-6 ml-auto mr-auto">
        <card type="user">
          <p class="card-text"></p>
          <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <h2>{{$t('users.workersPermit')}}</h2>
            <h4>{{ companyModel.name }} kt: {{ companyModel.identificationNumber }}</h4>
            <a @click="onAvatarClick">
              <img class="avatar" :src="'data:image/png;base64,' + model.base64ImageData" alt="..." />
              <h5 class="title">{{ model.fullName }}</h5>
              <h6 class="title">{{ model.identificationNumber }}</h6>
            </a>
            <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" />
            <p class="description">
              <a :href="'mailto:' + model.email">{{ model.email }}</a>

            </p>
            <p class="description">
              <a :href="'tel:' + model.phoneNumber">{{ model.phoneNumber }}</a>
            </p>
            <p class="description">
              <a>{{ model.employeeTitle }}</a>
            </p>
          </div>
          <template v-slot:footer v-if="!enableEdit && (userRole !== 'User' || userId === currentUserId)">
            <base-button class="col-md-12 ml-auto mr-auto" type="standard" @click="edit" fill>{{$t('users.editProfile')}}</base-button>
          </template>
        </card>
        <card v-if="enableEdit">
          <h5 slot="header" class="title">{{$t('users.editProfile')}}</h5>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.company')"
                :placeholder="$t('users.company')"
                v-model="model.company"
                disabled
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.identificationNumber')"
                v-model="companyModel.identificationNumber"
                :placeholder="$t('users.identificationNumber')"
                disabled
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.email')"
                type="email"
                :placeholder="$t('users.email')"
                v-model="model.email"
                disabled
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.role')"
                type="role"
                :placeholder="$t('users.role')"
                v-model="model.role"
                disabled
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.employeeTitle')"
                :placeholder="$t('users.employeeTitle')"
                v-model="model.employeeTitle"
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.identificationNumber')"
                v-model="model.identificationNumber"
                :placeholder="$t('users.identificationNumber')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('users.firstName')"
                v-model="model.firstName"
                :placeholder="$t('users.firstName')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('users.lastName')"
                v-model="model.lastName"
                :placeholder="$t('users.lastName')"
              >
              </base-input>
            </div>
          </div>
          <div class="row" v-if="model.language">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('users.phoneNumber')"
                v-model="model.phoneNumber"
                :placeholder="$t('users.phoneNumber')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <label>{{$t('users.language')}}</label>
              <div>
                <el-select class="select-sizing" :class="selectClass" v-model="languageOption" :placeholder="$t('users.selectLanguage')" :label="$t('users.language')">
                  <el-option
                    :class="selectClass"
                    v-for="(info, code) in languageMap"
                    :key="code"
                    :label="`${info.flag} ${info.name}`"
                    :value="code"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row" v-if="userRole !== 'User'">
            <div class="col-md-6 pr-md-1 d-flex">
              <base-checkbox v-model="model.disabled"><p>{{$t('users.disabled')}}</p></base-checkbox>
            </div>
            <div v-if="userId === currentUserId" class="col-md-6 pr-md-1 d-flex">
              <base-checkbox v-model="model.receiveAdminEmails"><p>{{$t('users.adminEmails')}}</p></base-checkbox>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <base-button slot="footer" type="standard" @click="handleUserUpdate" fill>{{$t('common.save')}}</base-button>
          </div>
        </card>
      </div>
    </card>
    <card :title="cardTitle" v-if="userRole !== 'User' && userId !== currentUserId">
      <template v-slot:table-select>
        <div>
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              v-for="(option, index) in filteredTablesCategories"
              :key="option.name"
              class="btn btn-sm btn-simple"
              :class="[{ active: activeIndex === index}, buttonClass]"
              :id="index"
            >
              <input
                type="radio"
                @click="initTable(index)"
                name="options"
                autocomplete="off"
                :checked="activeIndex === index"
              />
              <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
              <span class="d-block d-sm-none">
                <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
              </span>
            </label>
          </div>
        </div>
      </template>
      <div class="col-md-12 ml-auto mr-auto" v-if="reservationDataReady">
        <div class="table-responsive">
          <el-base-table
            :tableData="userReservationTable.data"
            :tableColumns="userReservationTable.columns"
            :allowClick="true"
            @row-click="goToTool"
          />
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              {{ $t('pagination.showing') }} {{ myToolsFrom + 1 }} {{ $t('pagination.to') }} {{ myToolsTo }} {{ $t('pagination.of') }} {{ myToolsTotal }} {{ $t('pagination.entries') }}
            </p>
          </div>
          <base-pagination
            @input="myToolsUpdatePage"
            class="pagination-no-border"
            v-model="myToolsPagination.currentPage"
            :per-page="myToolsPagination.perPage"
            :total="myToolsTotal"
          >
          </base-pagination>
        </div>
      </div>
      <div class="col-md-12 ml-auto mr-auto" v-if="externalReservationDataReady">
        <div class="table-responsive">
          <el-base-table
            :tableData="externalReservationsTable.data"
            :tableColumns="externalReservationsTable.columns"
            :allowClick="true"
            @row-click="goToTool"
          />
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              {{ $t('pagination.showing') }} {{ externalReservationFrom + 1 }} {{ $t('pagination.to') }} {{ externalReservationTo }} {{ $t('pagination.of') }} {{ externalReservationTotal }} {{ $t('pagination.entries') }}
            </p>
          </div>
          <base-pagination
            @input="externalReservationUpdatePage"
            class="pagination-no-border"
            v-model="externalReservationPagination.currentPage"
            :per-page="externalReservationPagination.perPage"
            :total="externalReservationTotal"
          >
          </base-pagination>
        </div>
      </div>
      <div class="col-md-12 ml-auto mr-auto" v-if="carsTableDataReady">
        <div class="table-responsive">
          <el-base-table
            :tableData="myCarsTable.data"
            :tableColumns="myCarsTable.columns"
            :allowClick="true"
            @row-click="goToCar"
          />
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              {{ $t('pagination.showing') }} {{ myCarsFrom + 1 }} {{ $t('pagination.to') }} {{ myCarsTo }} {{ $t('pagination.of') }} {{ myCarsTotal }} {{ $t('pagination.entries') }}
            </p>
          </div>
          <base-pagination
            @input="myCarsUpdatePage"
            class="pagination-no-border"
            v-model="myCarsPagination.currentPage"
            :per-page="myCarsPagination.perPage"
            :total="myCarsTotal"
          >
          </base-pagination>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import api from "../../services/api"
import {Select, Option,Table, TableColumn } from 'element-ui';
import { EventBus } from '../../components/event-bus.js';
import imageCompression from 'browser-image-compression';
import { BaseCheckbox } from 'src/components/index';
import { defaultUserImage } from '../../components/Image Bytes/imagebytes'
import { BasePagination } from 'src/components';
import ElBaseTable from "../../components/ElBaseTable.vue";

export default {
  components: {
    BaseCheckbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-base-table': ElBaseTable,
    BasePagination
  },
  computed: {
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    },
    buttonClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'btn-standard';
      } else {
        return 'btn-primary';
      }
    },
    myToolsTo() {
      let highBound = this.myToolsFrom + this.myToolsPagination.perPage;
      if (this.myToolsTotal < highBound) {
        highBound = this.myToolsTotal;
      }
      return highBound;
    },
    myToolsFrom() {
      return this.myToolsPagination.perPage * (this.myToolsPagination.currentPage - 1);
    },
    myToolsTotal() {
      return this.myToolsPagination.total;
    },
    myCarsTo() {
      let highBound = this.myCarsFrom + this.myCarsPagination.perPage;
      if (this.myCarsTotal < highBound) {
        highBound = this.myCarsTotal;
      }
      return highBound;
    },
    myCarsFrom() {
      return this.myCarsPagination.perPage * (this.myCarsPagination.currentPage - 1);
    },
    myCarsTotal() {
      return this.myCarsPagination.total;
    },

    externalReservationTo() {
      let highBound = this.externalReservationFrom + this.externalReservationPagination.perPage;
      if (this.externalReservationTotal < highBound) {
        highBound = this.externalReservationTotal;
      }
      return highBound;
    },
    externalReservationFrom() {
      return this.externalReservationPagination.perPage * (this.externalReservationPagination.currentPage - 1);
    },
    externalReservationTotal() {
      return this.externalReservationPagination.total;
    },
    myTablesCategories() {
      // return [{ name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run', showUser:false }];
      return this.model.role !== 'User' ?
        [
          { name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings' },
          { name: this.$t('cars.myCars'), icon: 'tim-icons icon-bus-front-12' },
          { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run' }
        ]
        :
        [
          { name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings' },
          { name: this.$t('cars.myCars'), icon: 'tim-icons icon-bus-front-12' },
        ];
    },
    filteredTablesCategories() {
      return this.myTablesCategories.filter(option => option.showUser || this.userRole !== 'User');
    },
  },
  data() {
    return {
      model: {
        company: "",
        email: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        fullName: "",
        language: "",
        base64ImageData: "",
        identificationNumber: null,
        employeeTitle: null,
        receiveAdminEmails: false,
        role: "",
        disabled: false
      },
      companyModel: {
        name: "",
        identificationNumber: "",
        base64ImageData: "",
      },
      userInfoLoaded: false,
      languageMap: {
        'en-US': { name: 'English', flag: '🇺🇸' },
        'is-IS': { name: 'Íslenska', flag: '🇮🇸' },
        'pl-PL': { name: 'Polski', flag: '🇵🇱' },
        'lt-LT': { name: 'Lietuvis', flag: '🇱🇹' }
      },
      languageOption: '',
      enableEdit: false,
      userRole: '',
      userId: '',
      currentUserId: '',
      userReservationTable: {
        title: this.$t('tools.myTools'),
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
          { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
          { prop: 'daysLeft', label: this.$t('tools.daysLeft'), minWidth: 60 },
          { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 60},
          { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 60},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'common.project':'project', 'tools.daysLeft': 'daysLeft', 'tools.reservation': 'reservationDate','tools.quantity': 'quantity'}},
        ],
        data: [],
      },
      myCarsTable: {
        title: this.$t('cars.myCars'),
        columns: [
          { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
          { prop: 'licencePlate', label: this.$t('cars.licencePlate'), minWidth: 200 },
          { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.name': 'name', 'cars.licencePlate':'licencePlate','tools.reservation': 'reservationDate'}},
        ],
        data: [],
      },
      externalReservationsTable: {
        title: this.$t('projects.toolsLoanedRentedOnProject'),
        columns: [
          { prop: 'name', label: this.$t('common.tool'), minWidth: 200 },
          { prop: 'status', label: this.$t('tools.loanedRented'), minWidth: 80 },
          { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 90 },
          { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
          { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 60},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'name', 'common.status':'status', 'tools.loanedRentedTo': 'externalUser', 'tools.reservation': 'reservationDate','tools.quantity': 'quantity'}},
        ],
        data: [],
      },
      myToolsPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      myCarsPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      externalReservationPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      reservationDataReady: false,
      carsTableDataReady: false,
      externalReservationDataReady: false,

      activeIndex: 0,
      cardTitle: this.$t('tools.myTools')
    };
  },
  created() {
    if (Cookies.get('defaultPageSize'))
    {
      this.myToolsPagination.perPage = Number(Cookies.get('defaultPageSize'))
      this.myCarsPagination.perPage = Number(Cookies.get('defaultPageSize'))
      this.externalReservationPagination.perPage = Number(Cookies.get('defaultPageSize'))
    }
    this.fetchUserData();
    this.fetchCustomerData();
    this.fetchUserReservations();

  },
  methods: {
    initTable(index) {
      this.activeIndex = index;
      if (this.model.role !== 'User') {
        if (this.activeIndex === 0) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.cardTitle = this.$t('tools.myTools');
          if (this.userReservationTable.data.length <= 0) {
            this.fetchUserReservations();
          }
          else {
            this.reservationDataReady = true;
          }
        }
        else if (this.activeIndex === 1) {
          this.reservationDataReady = false;
          this.externalReservationDataReady = false;
          this.cardTitle = this.$t('cars.myCars')
          if (this.myCarsTable.data.length <= 0) {
            this.fetchCarsData();
          }
          else {
            this.carsTableDataReady = true;
          }
        }
        else {
          this.reservationDataReady = false;
          this.carsTableDataReady = false;
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          if (this.externalReservationsTable.data.length <= 0) {
            this.fetchExternalReservationData();
          }
          else {
            this.externalReservationDataReady = true;
          }
        }
      }
      else {
        if (this.activeIndex === 0) {
          this.carsTableDataReady = false;
          this.cardTitle = this.$t('tools.myTools');
          if (this.userReservationTable.data.length <= 0) {
            this.fetchUserReservations();
          }
          else {
            this.reservationDataReady = true;
          }
        }
        else if (this.activeIndex === 1) {
          this.reservationDataReady = false;
          this.cardTitle = this.$t('cars.myCars')
          if (this.myCarsTable.data.length <= 0) {
            this.fetchCarsData();
          }
          else {
            this.carsTableDataReady = true;
          }
        }
      }
    },
    fetchUserData() {
      const decodedToken = jwtDecode(Cookies.get('accessToken'));
      const CompanyName = `${decodedToken.CustomerName}`;
      this.userRole = `${decodedToken.role}`;
      this.currentUserId = decodedToken.UserIdentifier;
      this.userId = this.$route.params.userId ? this.$route.params.userId : decodedToken.UserIdentifier;

      let userUrl = `/User/${this.userId}`
      api.get(userUrl)
        .then(response => {
          this.model.company = CompanyName;
          this.model.email = response.data.email;
          this.model.phoneNumber = response.data.phoneNumber;
          this.model.firstName = response.data.firstName;
          this.model.fullName = response.data.firstName + " " + response.data.lastName;
          this.model.lastName = response.data.lastName;
          this.model.language = response.data.language;
          this.model.identificationNumber = response.data.identificationNumber;
          this.model.employeeTitle = response.data.employeeTitle;
          this.model.base64ImageData = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage
          this.model.role = response.data.role
          this.model.disabled = response.data.disabled
          // We need to store the initial option in a separate value. Using model.language will not trigger rerender for some reason
          this.languageOption = this.model.language;
          this.model.receiveAdminEmails = response.data.receiveAdminEmails;

          this.userInfoLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    fetchCustomerData() {
      let customerUrl = `/Customers/Me`
      api.get(customerUrl)
        .then(response => {
          this.companyModel.name = response.data.value.name;
          this.companyModel.identificationNumber = response.data.value.identificationNumber;
          this.companyModel.base64ImageData = response.data.value.logoBytes;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    async fetchUserReservations() {
      let toolReserveUrl = `/Tool/Reservations?userId=${this.userId}&currentPage=${this.myToolsPagination.currentPage}&pageSize=${this.myToolsPagination.perPage}`
      api.get(toolReserveUrl)
      .then(response => {
        this.myToolsPagination.total = response.data.totalItems;
        this.userReservationTable.data = response.data.result.map(item => {
          let daysLeft = ''
          if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
            const currentDate = new Date();
            const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
            const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
            daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
          }
          else{
            daysLeft = '∞'
          }
          let toolName = `#${item.tool.customerToolId} ${item.tool.name}`;

          return {
            reservationId: item.id,
            toolId: item.tool.id,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
            tool: toolName,
            project: item.project.name,
            reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            daysLeft: daysLeft,
            quantity: item.quantity
          };
        });
        this.reservationDataReady = true;
      })
      .catch(error => {
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
        console.error(error);
      });
    },
    async fetchCarsData() {
      let toolGroupReserveUrl = `/ToolGroup/Reservations/?userId=${this.userId}&currentPage=${this.myCarsPagination.currentPage}&pageSize=${this.myCarsPagination.perPage}&toolGroupTypeId=1`
      api.get(toolGroupReserveUrl)
      .then(response => {
        this.myCarsPagination.total = response.data.totalItems;
        this.myCarsTable.data = response.data.result.map(item => {
          let licencePlate = ''
          if(item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
          {
            licencePlate = item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
          }
          return {
            toolGroupId: item.toolGroupId,
            name: item.toolGroup.name,
            licencePlate: licencePlate,
            reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
          };
        });
        this.carsTableDataReady = true;
      })
      .catch(error => {
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          console.error(error);
      });
    },
    async fetchExternalReservationData() {
      let exernalReservationUrl = `/Tool/Reservations/External?userId=${this.userId}&currentPage=${this.externalReservationPagination.currentPage}&pageSize=${this.externalReservationPagination.perPage}`
      api.get(exernalReservationUrl)
      .then(response => {
        this.externalReservationPagination.total = response.data.totalItems;
        this.externalReservationsTable.data = response.data.result.map(item => {
          let loanRent = item.rented ? this.$t('tools.rented') : loanRent = this.$t('tools.loaned');
          // Add the prefix to the tool name if the cookie is true
          let toolName = `#${item.tool.customerToolId} ${item.tool.name}`;

          return {
            toolId: item.tool.id,
            name: toolName,
            externalUser: item.externalUser,
            status: loanRent,
            reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            quantity: item.quantity
          };
        });
        this.externalReservationDataReady = true;
      })
      .catch(error => {
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          console.error(error);
      });
    },

    getLanguageInfo(code) {
      return this.languageMap[code];
    },
    edit() {
      this.enableEdit = true;
    },
    handleUserUpdate() {
      let userUrl = `/User/${this.userId}`
      this.model.language = this.languageOption;
      api.put(userUrl, this.model)
        .then(response => {
          // Need to reset all values after the update so the card is correctly updated
          this.model.email = response.data.email;
          this.model.phoneNumber = response.data.phoneNumber;
          this.model.firstName = response.data.firstName;
          this.model.fullName = response.data.firstName + " " + response.data.lastName;
          this.model.lastName = response.data.lastName;
          this.model.language = response.data.language;
          this.model.identificationNumber = response.data.identificationNumber;
          this.model.employeeTitle = response.data.employeeTitle;
          this.model.base64ImageData = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage

          if(this.userId === this.currentUserId) {
            Cookies.set('locale',response.data.language,{ secure: true, httpOnly: false })
            this.$i18n.locale = this.model.language;
          }

          this.$notify({
            message: this.$t('users.updated'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.enableEdit = false;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    onAvatarClick() {
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      // Compress the image
      let options = {
        maxSizeMB: 0.5,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1920,  // (default: undefined)
        useWebWorker: true,      // (default: true)
      };
      let file = await imageCompression(event.target.files[0], options);
      if(file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.model.base64ImageData = btoa(binary);
          let userUrl = `/User/${this.userId}`
          api.put(userUrl, { userImageBytes: this.model.base64ImageData })
            .then(response => {
              this.$notify({
                message: this.$t('users.imageUpdated'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });
              if (this.userId === this.currentUserId) {
                EventBus.$emit('userImageUpdated', this.model.base64ImageData);
              }
            })
            .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: this.$t('common.unableToUpdate'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
            });
        };
        reader.readAsArrayBuffer(file);
      }
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.toolId } });
    },
    goToCar(item) {
      this.$router.push({ name: 'Car', params: { toolGroupId: item.toolGroupId.toString() } });
    },
    myToolsUpdatePage (item) {
      this.myToolsPagination.currentPage = item;
      this.fetchUserReservations();
    },
    myCarsUpdatePage (item) {
      this.myCarsPagination.currentPage = item;
      this.fetchCarsData();
    },
    externalReservationUpdatePage (item) {
      this.externalReservationPagination.currentPage = item;
      this.fetchExternalReservationData();
    },
    getReservationLoanRentTranslation(reservationLoanRent)
    {
       switch (reservationLoanRent) {
            case "Loaned":
              return this.$t('tools.loaned')
            case "Rented":
              return this.$t('tools.rented')
            case "Reserved":
              return this.$t('tools.reserved')
            default:
              return 'N/A'
          }
    },
  }
};
</script>
<style scoped>
  .select-sizing {
    width: 100%
  }
</style>
