<template>
<div>
  <div class="row">
    <div class="col-md-6 position-relative">
        <h1>{{ project.name }}</h1>
        <h2>{{ project.key }}</h2>
        <h3 v-if="userRole !== 'User'">{{ GetStatusTranslation(project.projectStatusId) }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <card :title="cardTitle">
        <template v-slot:table-select>
          <div>
            <div
              class="btn-group btn-group-toggle"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in myTablesCategories"
                :key="option.name"
                class="btn btn-sm btn-simple"
                :class="[{ active: activeIndex === index}, selectClass]"
                :id="index"
              >
                <input
                  type="radio"
                  @click="initTable(index)"
                  name="options"
                  autocomplete="off"
                  :checked="activeIndex === index"
                />
                <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                  <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                </span>
              </label>
            </div>
          </div>
        </template>
        <template v-if="tableDataReady">
          <div class="table-responsive">
            <el-base-table
              :tableData="tableData.data"
              :tableColumns="tableData.columns"
              :tableSettings="tableData.settings"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ pagination_from + 1 }} {{ $t('pagination.to') }} {{ pagination_to }} {{ $t('pagination.of') }} {{ pagination_total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
              @input="updatePagination"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination_total"
            >
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import api from "../../services/api";  // Import your API service
import ElBaseTable from "../../components/ElBaseTable.vue";
import { BasePagination, Modal } from 'src/components';


export default {
  components: {
    BasePagination,
    'el-base-table': ElBaseTable,
    Modal
  },
  computed: {
    pagination_to() {
      let highBound = this.pagination_from + this.pagination.perPage;
      if (this.pagination_total < highBound) {
        highBound = this.pagination_total;
      }
      return highBound;
    },
    pagination_from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    pagination_total() {
      return this.pagination.total;
    },
    myTablesCategories() {
      // How the table data is loaded is highly dependant on the ordering in this list, any changes here will need to be reflected in the init table method
      return this.userRole !== 'User' ?
        [{ name: this.$t('projects.toolsAssignedToProject'), icon: 'tim-icons icon-settings' }, { name: this.$t('projects.reservationHistory'), icon: 'tim-icons icon-notes' }, { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run' }, { name: this.$t('projects.loanRentHistory'), icon: 'tim-icons icon-paper' }]
        : [{ name: this.$t('projects.toolsAssignedToProject'), icon: 'tim-icons icon-settings' }];
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'btn-standard';
      } else {
        return 'btn-primary';
      }
    }
  },
  data() {
    return {
      activeIndex: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      projectId: "",
      project: {},
      tableData: {},
      tableDataReady: false,
      isMobile: window.innerWidth <= 768,
      userRole: "",
      cardTitle: this.$t('projects.toolsAssignedToProject'),
      showUpdateReservationDaysModal: false,
      updateDays: '',
      refreshHistory: false,
      latestUnreservedId: '',
      activeEntity: null
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
  },
  created() {
    if (Cookies.get('defaultPageSize'))
    {
      this.pagination.perPage = Number(Cookies.get('defaultPageSize'))
    }

    window.addEventListener('resize', this.updateIsMobile);
    this.projectId = this.$route.params.projectId
    let projectUrl = `/Project/${this.projectId}`;
    api.get(projectUrl)
      .then(response => {
        this.project = response.data;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role;
    this.fetchCurrentlyReservedTableData();  // Fetch the table data
  },
  methods: {
    initTable(index) {
      this.activeIndex = index;
      this.tableDataReady = false;
      this.fetchNewData();
    },
    updatePagination(item){
      this.pagination.currentPage = item;
      this.fetchNewData();
    },
    fetchNewData() {
      // Currently reserved and historical data fetch should be combined
      if (this.userRole !== 'User') {
        if (this.activeIndex === 0) {
          this.cardTitle = this.$t('projects.toolsAssignedToProject');
          this.activeEntity = 'ToolReservation';
          this.fetchCurrentlyReservedTableData();
        }
        else if (this.activeIndex === 1) {
          this.cardTitle = this.$t('projects.reservationHistory');
          this.activeEntity = 'ToolReservation';
          this.fetchHistoryTableData();
        }
        else if (this.activeIndex === 2) {
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          this.activeEntity = 'ExternalReservation';
          this.fetchCurrentlyLoanedRentedTableData();
        }
        else {
          this.cardTitle = this.$t('projects.loanRentHistory');
          this.activeEntity = 'ExternalReservation';
          this.fetchLoanRentHistoryTableData();
        }
      }
      else {
        this.cardTitle = this.$t('projects.toolsAssignedToProject');
        this.activeEntity = 'ToolReservation';
        this.fetchCurrentlyReservedTableData();
      }
    },
    async fetchLoanRentHistoryTableData(){
      if(this.userRole === 'User') {
        return;
      }

      this.tableData = {
        title: this.$t('projects.loanRentHistory'),
        settings: {
          allowClick: true,
          handleRowClick: this.goToTool,
        },
        columns: [
          { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
          { prop: 'externalReservationType', label: this.$t('toolGroups.reservationType'), minWidth: 200 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 80 },
          { prop: 'returned', label: this.$t('tools.returned'), minWidth: 80 },
          { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
          { prop: 'loanedRentedBy', label: this.$t('tools.loanedRentedBy'), minWidth: 200 },
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'toolGroups.reservationType':'externalReservationType', 'tools.loanedRentedDate': 'reserved', 'tools.returned':'returned', 'common.totalAmount':'totalPrice', 'tools.loanedRentedBy': 'loanedRentedBy'}},
        ],
        data: [],
        
      }
      let toolReserveUrl = `/Tool/Reservations/External?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&projectId=${this.projectId}&unreserved=true`;
      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          let reservationType = this.$t('tools.loaned') + " - " + item.externalUser;
          if(item.rented)
          {
            reservationType = this.$t('tools.rented') + " - " + item.externalUser;
          }

          return {
            id: item.id,
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: item.tool.name,
            customerToolId: '#' + item.tool.customerToolId,
            reserved: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            returned: new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            externalReservationType: reservationType,
            loanedRentedBy: this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName,
            totalPrice: item.totalPrice
          };
        });
        this.tableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }

    },

    async fetchHistoryTableData() {
      if(this.userRole === 'User') {
        return;
      }

      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&projectId=${this.projectId}&unreserved=true`;
      this.tableData = {
        title: this.$t('projects.reservationHistory'),
        settings: {
          allowClick: true,
          handleRowClick: this.goToTool,
        },
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
          { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
          { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
          { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'tools.reserved': 'reserved','tools.returned': 'returned', 'common.totalAmount':'totalPrice', 'tools.reservedBy': 'reserved by',}},
        ],
        data: [],
      }
      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: item.tool.name,
            customerToolId: '#' + item.tool.customerToolId,
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'returned': new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            'reserved by': this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName,
            totalPrice: item.totalPrice,
            id: item.id
          };
        });
        this.tableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },

    async fetchCurrentlyLoanedRentedTableData(){
      if(this.userRole === 'User') {
        return;
      }

      let toolReserveUrl = `/Tool/Reservations/External?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&projectId=${this.projectId}`;
      
      this.tableData = {
        title: this.$t('projects.toolsLoanedRentedOnProject'),
        settings: {
          allowClick: true,
          handleRowClick: this.goToTool
        },
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
          { prop: 'loanedRented', label: this.$t('tools.loanedRented'), minWidth: 60 },
          { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 100 },
          { prop: 'reserved', label: this.$t('tools.loanedRentedDate'), minWidth: 80 },
          { prop: 'loanedRentedBy', label: this.$t('tools.loanedRentedBy'), minWidth: 170 },
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'tools.loanedRented': 'loanedRented', 'tools.loanedRentedTo': 'externalUser', 'tools.loanedRentedDate': 'reserved', 'tools.loanedRentedBy': 'loanedRentedBy'}},
        ],
        data: []
      }

      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          let loanRent = this.$t('tools.loaned');
          if(item.rented)
          {
            loanRent = this.$t('tools.rented');
          }
          return {
              toolId: item.toolId,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
              customerToolId: '#' + item.tool.customerToolId,
              tool: item.tool.name,
              reserved: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              loanedRented: loanRent,
              externalUser: item.externalUser,
              loanedRentedBy: this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName //should be username
            };
        });
        this.tableDataReady = true;
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },

    async fetchCurrentlyReservedTableData() {
      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&projectId=${this.projectId}`;
      this.tableData = {
        title: this.$t('projects.toolsAssignedToProject'),
        settings: {
          allowClick: true,
          handleRowClick: this.goToTool
        },
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
          { prop: 'days left', label: this.$t('tools.daysLeft'), minWidth: 60 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
          { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.daysLeft': 'days left', 'tools.reservedBy': 'reserved by', 'tools.reserved': 'reserved'}},
        ],
        data: [],
      }

      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          let daysLeft = ''
          if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
            const currentDate = new Date();
            const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
            const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
            daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
          }
          else{
            daysLeft = '∞'
          }

          return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            customerToolId: '#' + item.tool.customerToolId,
            tool: item.tool.name,
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'days left': daysLeft,
            'reserved by': item.user.firstName + " " + item.user.lastName //should be username
          };
        });
        this.tableDataReady = true;
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.toolId} });
    },
    GetStatusTranslation(statusId){
      switch(statusId){
        case 1:
          return this.$t('projectStatus.notStarted')
        case 2:
          return this.$t('projectStatus.active')
        case 3:
          return this.$t('projectStatus.onHold')
        case 4:
          return this.$t('projectStatus.finished')
      }
    },
    getReservationType(item) {
      var reservationType = '';
      if(item.loaned === true) {
        reservationType = this.getReservationLoanRentTranslation("Loaned") + " - " + item.externalUser;
      }
      else if(item.rented === true) {
        reservationType = this.getReservationLoanRentTranslation("Rented") + " - " + item.externalUser;
      }
      else {
        reservationType = this.getReservationLoanRentTranslation("Reserved");
      }
      return reservationType;
    },
    getReservationLoanRentTranslation(reservationLoanRent)
    {
       switch (reservationLoanRent) {
            case "Loaned":
              return this.$t('tools.loaned')
            case "Rented":
              return this.$t('tools.rented')
            case "Reserved":
              return this.$t('tools.reserved')
            default:
              return 'N/A'
          }
    }
  }
};
</script>
<style scoped>
</style>
