<template>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12">
        <create-tool-form v-if="showCreateToolForm" @close="closeCreateToolForm" @refreshData="fetchData" @refreshToolTypesData="fetchToolTypesData"></create-tool-form>
      </div>
        <card card-body-classes="table-full-width" :title="this.cardTitle" :onAddObject="this.userRole !== 'User' ? openCreateToolForm : null">
          <div v-if="this.toolsDataReady">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <base-input :class="{ 'search-mobile': isMobile }">
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    :placeholder="$t('common.searchRecords')"
                    v-model="searchQuery"
                    aria-controls="datatables"
                    @keyup.native="search"
                    @clear="handleClearSearch"
                  >
                  </el-input>
                </base-input>

                <div class="col-9 status-select" v-if="isMobile" >
                  <select v-model="statusFilter" class="form-control" :placeholder="$t('common.status')" @change="fetchData">
                    <option
                      v-for="status in statusOptions"
                      :key="status.id"
                      :label="status.status"
                      :value="status.status"
                    >
                    </option>
                  </select>
                </div>
                <div class="col-9 status-select" v-if="isMobile" >
                  <select  v-model="toolTypeFilter" class="form-control" :placeholder="$t('common.type')" @change="fetchData">
                    <option
                      v-for="toolType in toolTypeOptions"
                      :key="toolType.id"
                      :label="toolType.type"
                      :value="toolType.type"
                    >
                    </option>
                  </select>
                </div>
              </div>

              <!-- Desktop Table -->
              <div v-if="toolTypeOptionsAvailable">

                <el-table v-if="!isMobile" :data="tools" @row-click="goToTool">
                  <el-table-column
                    prop="image"
                    :label="$t('common.image')"
                    width="80"
                  >
                    <template slot-scope="scope">
                      <img :src="scope.row.image" alt="Tool Image" />
                      </template>
                  </el-table-column>
                    <el-table-column
                      class="mobile-stack"
                      v-for="column in filteredTableColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                    >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row[column.prop] }}
                      <br v-if="column.prop === 'status' && scope.row.reservingUser !== ''" />
                      <span v-if="column.prop === 'status' && scope.row.reservingUser !== ''" class="status-info" >{{$t('common.user')}}: </span>
                      <a v-if="column.prop === 'status' && scope.row.reservingUser !== ''" @click.stop="navigateToUserProfile(scope.row.reservingUserId)" class="clickable-link-small-font">
                      {{ scope.row.reservingUser}}
                      </a>
                      <br v-if="column.prop === 'status' && scope.row.project !== ''"/>
                      <span v-if="column.prop === 'status' && scope.row.project !== ''" class="status-info" >{{$t('common.project')}}: </span>
                      <a v-if="column.prop === 'status' && scope.row.project !== ''" @click.stop="navigateToProject(scope.row.projectId)" class="clickable-link-small-font">
                      {{ scope.row.project}}
                      </a>
                      <br v-if="column.prop === 'status' && scope.row.car !== ''"/>
                      <span v-if="column.prop === 'status' && scope.row.car !== ''" class="status-info" >{{$t('cars.car')}}: </span>
                      <a v-if="column.prop === 'status' && scope.row.car !== ''" @click.stop="navigateToCar(scope.row.carId)" class="clickable-link-small-font">
                      {{ scope.row.car}} - {{ scope.row.carLicensePlate}}
                      </a>

                      <br v-if="column.prop === 'status' && scope.row.externalUser !== ''"/>
                      <span v-if="column.prop === 'status' && scope.row.externalUser !== ''" class="status-info" >{{$t('tools.loanedRentedTo')}}: {{ scope.row.externalUser}}</span>

                      <br v-if="column.prop === 'status' && scope.row.totalUse !== ''"/>
                      <span v-if="column.prop === 'status' && scope.row.totalUse !== ''" class="status-info" >{{$t('tools.totalUsed')}}: {{ scope.row.totalUse}}</span>
                  </div>
                </template>

                      <template slot="header" >
                        <div v-if="column.label === $t('common.status')">
                          <el-select v-model="statusFilter" :class="selectClass" :placeholder="$t('common.status')" @change="fetchData">
                            <el-option
                              :class="selectClass"
                              v-for="status in statusOptions"
                              :key="status.id"
                              :label="status.status"
                              :value="status.status"

                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div v-else-if="column.label === $t('common.type')">
                          <el-select v-model="toolTypeFilter" :class="selectClass" :placeholder="$t('common.type')" @change="fetchData">
                            <el-option
                              :class="selectClass"
                              v-for="toolType in toolTypeOptions"
                              :key="toolType.id"
                              :label="toolType.type"
                              :value="toolType.type"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div v-else>
                          {{ column.label }}
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column v-if="this.userRole !== 'User'" :min-width="100" align="right" label="">
                    <div slot-scope="props">
                      <base-button
                        @click.native.stop="handleEdit(props.$index, props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                      <base-button
                        @click.native.stop="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </div>
                  </el-table-column>
                </el-table>

                <!-- Mobile Table -->
                <el-table v-else :data="tools" @row-click="goToTool">
                  <el-table-column :label="$t('common.image')">
                    <template slot-scope="scope">
                      <img :src="scope.row.image" alt="Tool Image" style="width: 250%; height: auto;" />
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('common.info')" min-width="150">
                    <template slot-scope="scope">
                      <div>
                        <div><strong>{{$t('common.customerToolId')}}:</strong> {{ scope.row.customerToolId }}</div>
                        <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                        <div><strong>{{$t('common.type')}}:</strong> {{ scope.row.toolTypeId }}</div>
                        <div><strong>{{$t('common.status')}}:</strong> {{ scope.row.status }}</div>
                        <div v-if="scope.row.reservingUser !== ''"><strong>{{$t('common.user')}}:</strong> <a @click.stop="navigateToUserProfile(scope.row.reservingUserId)" class="clickable-link">{{ scope.row.reservingUser }}</a></div>
                        <div v-if="scope.row.project !== ''"><strong>{{$t('common.project')}}:</strong> <a @click.stop="navigateToProject(scope.row.projectId)" class="clickable-link">{{ scope.row.project }}</a></div>
                        <div v-if="scope.row.car !== ''"><strong>{{$t('cars.car')}}:</strong> <a @click.stop="navigateToCar(scope.row.carId)" class="clickable-link">{{ scope.row.car }}</a></div>
                        <div v-if="scope.row.externalUser !== ''"><strong>{{$t('tools.loanedRentedTo')}}:</strong> {{ scope.row.externalUser }}</div>
                        <div v-if="scope.row.totalUse !== ''"><strong>{{$t('tools.totalUsed')}}:</strong> {{ scope.row.totalUse }}</div>

                        <div v-if="userRole !== 'User'">
                          <base-button
                            @click.native.stop="handleEdit(scope.$index, scope.row)"
                            class="edit btn-link"
                            type="warning"
                            size="sm"
                            icon
                          >
                            <i class="tim-icons icon-pencil"></i>
                          </base-button>
                          <base-button
                            @click.native.stop="handleDelete(scope.$index, scope.row)"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                          >
                            <i class="tim-icons icon-simple-remove"></i>
                          </base-button>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
              @input="updatePage"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </card>
    </div>
  </div>
</template>
<script>
import api from "../../services/api"
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import CreateToolForm from "./CreateToolForm.vue";
import { BasePagination } from 'src/components';
import { Table, TableColumn, Select, Option } from 'element-ui';
import swal from 'sweetalert2';

export default {
  components: {
    CreateToolForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mounted() {

    // Check if the cookies exists for search query and pages
    const savedSearchQuery = Cookies.get('toolsSearchQuery');
    if (savedSearchQuery) {
      this.searchQuery = savedSearchQuery;
    } else {
      this.searchQuery = '';
    }

    const savedPage = Cookies.get('toolsPage');
    if (savedPage) {
      this.pagination.currentPage = Number(savedPage);
    } else {
      this.pagination.currentPage = 1
    }
  },
  computed: {
    filteredTableColumns() {
      return this.tableColumns.filter(column => !column.vIf || column.vIf());
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  data() {
    return {
      toolsDataReady: false,
      cardTitle: '',
      isMobile: false,
      initalPage: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        {
          prop: 'customerToolId',
          label: this.$t('common.customerToolId'),
          minWidth: 50
        },
        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 100
        },
        {
          prop: 'description',
          label: this.$t('common.description'),
          minWidth: 200
        },
        {
          prop: 'toolTypeId',
          label: this.$t('common.type'),
          minWidth: 100
        },
        {
          prop: 'status',
          label: this.$t('common.status'),
          minWidth: 150
        }
      ],

      searchQuery: '',
      userRole: null,
      tools:[],
      showCreateToolForm: false,
      statusFilter:'',
      statusOptions:[
        {id: 0, status: this.$t('toolStatus.all') },
        {id: 1, status: this.$t('toolStatus.free') },
        {id: 2, status: this.$t('toolStatus.inUse') },
        {id: 3, status: this.$t('toolStatus.broken') },
        {id: 4, status: this.$t('toolStatus.outOfUse') },
        {id: 5, status: this.$t('toolStatus.loaned') },
        {id: 6, status: this.$t('toolStatus.rented') },
        {id: 7, status: this.$t('toolStatus.partlyUsed') },
        {id: 8, status: this.$t('toolStatus.fullyUsed') }

      ],
      toolTypeFilter: '',
      toolTypeOptions: [{id: 0, type: this.$t('tools.toolTypeStatus') }],
      toolTypeOptionsAvailable: false
    };
  },
  methods: {
    navigateToUserProfile(userId) {
      this.$router.push({ name: 'User', params: { userId: userId } });
    },
    navigateToProject(projectId) {
        this.$router.push({ name: 'Project', params: { projectId: projectId } });
    },
    navigateToCar(carId) {
        this.$router.push({ name: 'Car', params: { toolGroupId: carId } });
    },
    handleClearSearch()
    {
      Cookies.remove('toolsPage');
      this.pagination.currentPage = 1
      this.fetchData();
    },
    search(event){
      const queryLength = this.searchQuery.length;
      this.pagination.currentPage = 1;

      if (event.key === 'Backspace' || event.key === 'Enter' || Number.isInteger(Number(this.searchQuery))) {
        this.fetchData();
      }
      else {
        if (queryLength > 1) {
          if (queryLength % 2 === 0) {
            this.fetchData();
          }
        }
      }
    },

    openCreateToolForm()
    {
      this.showCreateToolForm = true;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    closeCreateToolForm()
    {
      this.showCreateToolForm = false;
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.id} });
    },
    updatePage(item){
      this.pagination.currentPage = item;
      Cookies.set('toolsPage', this.pagination.currentPage, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
      this.fetchData()
    },
    getStatusTranslation(statusId)
    {
       switch (statusId) {
            case 1:
              return this.$t('toolStatus.free')
            case 2:
              return this.$t('toolStatus.inUse')
            case 3:
              return this.$t('toolStatus.broken')
            case 4:
              return this.$t('toolStatus.outOfUse')
            case 5:
              return this.$t('toolStatus.loaned')
            case 6:
              return this.$t('toolStatus.rented')
            case 7:
              return this.$t('toolStatus.partlyUsed')
            case 8:
              return this.$t('toolStatus.fullyUsed')
            default:
              return 'N/A'
          }
    },
    deleteTool(item) {
      let deleteUrl = `/Tool/${item.id}`
      api.delete(deleteUrl)
        .then(response => {
          swal.fire({
            title: this.$t('delete.deleted'),
            text: `${this.$t('delete.youDeleted')} ${item.name}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
        this.tools = this.tools.filter(i => i.id != item.id);
      })
      .catch(error => {
        console.error('API request error:', error);
          this.$notify({
            message: `${this.$t('common.unableToDelete')} ${item.name}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
        });
      });
    },
    handleEdit(index, row)
    {
      this.$router.push({ name: 'Tool', params: { toolId: row.id, editMode:true} });
    },
    handleDelete(index, row) {

      swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteTool(row);
        }
      });
    },
    fetchData() {
      let statusUrlBit = ''

      if(this.statusFilter !== this.$t('toolStatus.all') && this.statusFilter !== '')
      {
        let id = this.statusOptions.filter(op => op.status === this.statusFilter)[0].id;
        statusUrlBit = `&toolStatusId=${id}`
      }

      let toolTypeUrlBit = ''

      if(this.toolTypeFilter !== this.$t('tools.toolTypeStatus') && this.toolTypeFilter !== '')
      {
        let id = this.toolTypeOptions.filter(op => op.type === this.toolTypeFilter)[0].id;
        toolTypeUrlBit = `&toolTypeId=${id}`
      }

      let toolsAvailableUrl = ''
      if(this.searchQuery === '')
      {
        Cookies.remove('toolsSearchQuery');
        toolsAvailableUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}` + statusUrlBit + toolTypeUrlBit
      }
      else
      {
        //Store searchQuery as cookie - 1 minute expiry time
        Cookies.set('toolsSearchQuery', this.searchQuery, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
        toolsAvailableUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`+ statusUrlBit + toolTypeUrlBit
      }
      api.get(toolsAvailableUrl)
        .then(response => {
          this.pagination.total = response.data.totalItems;
          this.tools = response.data.result.map(item => {
            //Fetch reservestion data
            let project = ''
            let projectId = ''
            let car = ''
            let carId = ''
            let carLicensePlate = ''
            let externalUser = ''
            let reservingUser = ''
            let reservingUserId = ''
            let totalUse = ''

            if(item.multiTool === true && item.inUse !== null && item.inUse !== 0)
            {
                totalUse = item.inUse + '/' + item.totalCount
            }
            else
            {
              //Normal reservation
              if(item.toolReservations && item.toolReservations.length > 0)
              {
                project = item.toolReservations[0].project.key
                projectId = item.toolReservations[0].project.id
                reservingUser = item.toolReservations[0].user.firstName
                reservingUserId = item.toolReservations[0].user.id
              }

              //External reservation
              if(item.externalToolReservations && item.externalToolReservations.length > 0)
              {
                reservingUser = item.externalToolReservations[0].user.firstName
                reservingUserId = item.externalToolReservations[0].user.id
                externalUser = item.externalToolReservations[0].externalUser
                if(item.externalToolReservations[0].project)
                {
                  project = item.externalToolReservations[0].project.key
                  projectId = item.externalToolReservations[0].project.id
                }
              }

              //Car
              if(item.toolToToolGroup !== null)
              {
                car = item.toolToToolGroup.toolGroup.name
                carId = item.toolToToolGroup.toolGroup.id
                carLicensePlate = item.toolToToolGroup.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
              }
            }


            return {
              id: item.id,
              locationId: item.locationId,
              toolTypeId: this.toolTypeOptions.filter(op => op.id === item.toolTypeId)[0].type,
              image: 'data:image/jpeg;base64,' + item.toolImageThumbnailBytes,
              status: this.getStatusTranslation(item.toolStatusId),
              name: item.name,
              customerToolId: '#' + item.customerToolId,
              description: item.description,
              sku: item.sku,
              dailyPrice: item.dailyPrice,
              brand: item.brandName,
              purchased: new Date(item.purchaseDate).toLocaleDateString('is-IS'),
              project: project,
              projectId: projectId,
              car: car,
              carId: carId,
              carLicensePlate: carLicensePlate,
              externalUser: externalUser,
              reservingUser: reservingUser,
              reservingUserId: reservingUserId,
              multiTool: item.multiTool,
              totalUse: totalUse
            };
        });
        this.toolsDataReady = true;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: `Unable to retrieve tool data: ${error.message} <br> Please refresh the page`,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    fetchToolTypesData() {
      let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
        this.toolTypeOptions.push(...response.data);
        this.fetchData();
        this.toolTypeOptionsAvailable = true;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  beforeMount() {
    this.checkMobile();
  },
  created() {
    if (Cookies.get('defaultPageSize'))
    {
      this.pagination.perPage = Number(Cookies.get('defaultPageSize'))
    }

    this.cardTitle = this.$t('tools.tools')
    window.addEventListener('resize', this.checkMobile);
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role

    if(window.innerWidth < 768){
      this.statusFilter = this.$t('toolStatus.all');
      this.toolTypeFilter = this.$t('tools.toolTypeStatus');
    }
    this.fetchToolTypesData();
  },
};
</script>
<style scoped>
  .mobile-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .mobile-image {
    flex: 1;
  }
  .mobile-data {
    flex: 3;
    display: flex;
    flex-direction: column;
  }
  .status-select{
    padding-bottom: 20px;
  }
  .search-mobile{
    margin-bottom: 0;
  }
  .clickable-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  .clickable-link-small-font {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
  }
  .status-info
  {
    font-size: 12px;

  }

.centered-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

