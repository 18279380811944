<template>
  <div class="row-md-12">
    <div class="row mt-5">
      <div class="col-md-12">
        <create-edit-project-form v-if="showCreateProjectForm && userRole !== 'User'"
          @close="closeCreateProjectForm"
          @refreshData="fetchData"
          :editMode="this.editMode"
          :keyProp="this.editKey"
          :nameProp="this.editName"
          :descriptionProp="this.editDescription"
          :editingProjectId="this.editProjectId"
          :selectedProjectStatusOptionProp="this.editProjectStatus"
          >
        </create-edit-project-form>
      </div>
      <div class="col-12">
        <card :title="$t('projects.projects')" :onCustomMethod="customMethod" :customMethodIcon="customIcon" :customMethodImage="getERPImage">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @keyup.native="search"
                  @clear="handleClearSearch"
                >
                </el-input>
              </base-input>
              <div class="col-9 status-select" >
              <select v-if="isMobile" v-model="statusFilter" class="form-control" :placeholder="$t('common.status')" @change="fetchData">
                <option
                  v-for="status in statusOptions"
                  :key="status.id"
                  :label="status.status"
                  :value="status.status"
                >
                </option>
              </select>
              </div>
            </div>
            <!-- Desktop Table -->
            <el-table v-if="!isMobile" :data="projects" @row-click="goToProject">
              <el-table-column
                class="mobile-stack"
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"

              >
                  <template slot="header" >
                    <div v-if="column.label === $t('common.status')">
                      <el-select v-model="statusFilter" :class="selectClass" :placeholder="$t('common.status')" @change="fetchData">
                        <el-option
                          :class="selectClass"
                          v-for="status in statusOptions"
                          :key="status.id"
                          :label="status.status"
                          :value="status.status"

                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      {{ column.label }}
                    </div>
                  </template>
              </el-table-column>
              <el-table-column v-if="enableProjectCreation" :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native.stop="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


            <!-- Mobile Table -->
            <el-table v-else :data="projects" @row-click="goToProject">
              <el-table-column :label="$t('common.info')" min-width="150">
                <template slot-scope="scope">
                  <div>
                    <div><strong>{{$t('projects.key')}}:</strong> {{ scope.row.key }}</div>
                    <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                    <div><strong>{{$t('common.status')}}:</strong> {{ scope.row.description }}</div>
                    <div><strong>{{$t('common.status')}}:</strong> {{ scope.row.status }}</div>
                  </div>
                </template>
              </el-table-column>
               <el-table-column v-if="this.enableProjectCreation" :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native.stop="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div></div
></template>
<script>
import { BasePagination } from "@/components";
import CreateEditProjectForm from "./CreateEditProjectForm";
import api from "../../services/api"
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Table, TableColumn, Select, Option } from 'element-ui';
import ElBaseTable from '../../components/ElBaseTable.vue';

export default {
  components: {
    BasePagination,
    CreateEditProjectForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-base-table': ElBaseTable
  },
  data() {
    return {
      //Edit properties
      editKey: '',
      editName: '',
      editDescription: '',
      editProjectId: '',
      editProjectStatus: this.$t('projectStatus.notStarted'),
      editMode: false,

      customerId: '',
      erpName: '',
      loading: false,
      enableProjectCreation: false,
      showCreateProjectForm: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      searchQuery: '',
      projects: [],
      isMobile: window.innerWidth <= 768,
      initalPage: true,
      statusFilter:'',
      statusOptions:[
        {id: 0, status: this.$t('projectStatus.all') },
        {id: 1, status: this.$t('projectStatus.notStarted') },
        {id: 2, status: this.$t('projectStatus.active') },
        {id: 3, status: this.$t('projectStatus.onHold') },
        {id: 4, status: this.$t('projectStatus.finished') },
      ],
      tableColumns: [

        {
          prop: 'key',
          label: this.$t('projects.key'),
          minWidth: 100
        },
        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 200
        },
        {
          prop: 'description',
          label: this.$t('common.description'),
          minWidth: 100
        },
        {
          prop: 'status',
          label: this.$t('common.status'),
          minWidth: 100
        }
      ],

    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    customIcon() {
    if (this.userRole === 'User') {
      return '';
    }
    return this.enableProjectCreation ? 'tim-icons icon-simple-add' : 'tim-icons icon-refresh-02';
    },
    getERPImage()
    {
      switch (this.erpName) {
        case 'DK':
          return 'img/dk-logo2.png';
        case 'Tímon':
          return 'img/timon.png';
        case 'Stólpi':
          return 'img/stolpi.png';
        default:
          return ''
      }
    },
    customMethod() {
      if (this.userRole === 'User') {
        return this.dummyMethod;
      }
      return this.enableProjectCreation ? this.openCreateProjectForm : this.sync;
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    },
  },
  methods: {
    dummyMethod() {},
    search(event){
      this.pagination.currentPage = 1;
      const queryLength = this.searchQuery.length;
      if (event.key === 'Backspace' || event.key === 'Enter') {
        this.fetchData();
      }
      else {
        if (queryLength > 1) {
          if (queryLength % 2 === 0) {
            this.fetchData();
          }
        }
      }
    },
    updatePage(item){
      this.pagination.currentPage = item;
      Cookies.set('projectsPage', this.pagination.currentPage, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
      this.fetchData()
    },
    openCreateProjectForm() {
      this.showCreateProjectForm = true;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    closeCreateProjectForm()
    {
        this.showCreateProjectForm = false;
        this.editMode = false;
        this.editDescription = '';
        this.editName = '';
        this.editKey = '';
        this.editProjectStatus = this.$t('projectStatus.notStarted')
        this.editProjectId = ''
    },
    handleClearSearch()
    {
      Cookies.remove('projectsPage');
      this.pagination.currentPage = 1
      this.fetchData();
    },
    goToProject(item) {
      this.$router.push({ name: 'Project', params: { projectId: item.id } });
    },
    fetchData() {
      let projectUrl = ''
      if(this.searchQuery === '')
      {
        Cookies.remove('projectsSearchQuery');
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
      }
      else
      {
        Cookies.set('projectsSearchQuery', this.searchQuery, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
      }

      if(this.statusFilter !== this.$t('projectStatus.all') && this.statusFilter !== '')
      {
        let id = this.statusOptions.filter(op => op.status === this.statusFilter)[0].id;
        projectUrl = projectUrl + `&projectStatusId=${id}`
      }

      api.get(projectUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.projects = response.data.result.map(item => {
          return {
              id: item.id,
              key: item.key,
              name: item.name,
              description: item.description,
              status: this.GetStatusTranslation(item.projectStatusId)
          };
          });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    sync() {
      this.loading = true;
      let syncUrl = `/Project/Sync`
      api.post(syncUrl)
      .then(response => {
        console.log(response)
        if(response.data.success){
          this.$notify({
            message: `${response.data.projectsCreated} ${this.$t('projects.projectsCreated')} <br> ${response.data.projectsUpdated} ${this.$t('projects.projectsUpdated')}`,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
          this.fetchData();
        }
        else{
          this.$notify({
          message: this.$t('common.somethingWentWrong') + " - " + response.data.errorMessage,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
        }
        this.loading = false;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.loading = false;
        this.$notify({
          message: this.$t('common.somethingWentWrong'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    GetStatusTranslation(statusId){
      switch(statusId){
        case 1:
          return this.$t('projectStatus.notStarted')
        case 2:
          return this.$t('projectStatus.active')
        case 3:
          return this.$t('projectStatus.onHold')
        case 4:
          return this.$t('projectStatus.finished')
      }
    },
    handleEdit(index, row)
    {
      this.editMode = true;
      this.editDescription = row.description;
      this.editName = row.name;
      this.editKey = row.key;
      this.editProjectStatus = row.status
      this.editProjectId = row.id
      this.openCreateProjectForm()
    },
  },
  created() {

    if (Cookies.get('defaultPageSize'))
    {
      this.pagination.perPage = Number(Cookies.get('defaultPageSize'))
    }

    if(this.isMobile){
      this.statusFilter = this.$t('projectStatus.all')
    }

    // Check if the cookie exists
    const savedSearchQuery = Cookies.get('projectsSearchQuery');
    if (savedSearchQuery) {
      this.searchQuery = savedSearchQuery;
    } else {
      this.searchQuery = '';
    }

    const savedPage = Cookies.get('projectsPage');
    if (savedPage) {
      this.pagination.currentPage = Number(savedPage);
    } else {
      this.pagination.currentPage = 1
    }

    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role
    this.customerId = decodedToken.CustomerId
    if (!Cookies.get('enableProjectCreation')) {
      let url = "Customers/CustomerSettings?key=EnableProjectCreation";
      api.get(url)
        .then(response => {
          if (response.data.length > 0) {
            Cookies.set('enableProjectCreation', response.data[0].value === 'true');
            this.enableProjectCreation = response.data[0].value === 'true';
          }
          else {
            Cookies.set('enableProjectCreation', false);
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    } else {
    // If the cookie exists, set the component data property
        this.enableProjectCreation = Cookies.get('enableProjectCreation') === 'true';
    }

      //Get ERP:
      if (Cookies.get(this.customerId + '-' + 'erpName'))
      {
        this.erpName = Cookies.get(this.customerId + '-' + 'erpName')
      }
      else
      {
        let url = "Customers/Me";
        api.get(url)
          .then(response => {
            console.log(response)
            if (response.data.value) {
              Cookies.set(this.customerId + '-' + 'erpName', response.data.value.erpName);
              this.erpName = response.data.value.erpName;
            }
            else {
              Cookies.set(this.customerId + '-' + 'erpName', '');
            }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          });
      }


    this.fetchData();
  },
};
</script>
<style scoped>
  .status-select{
    padding-bottom: 20px;
  }
</style>
